export const WS_TYPES = {
  WELCOME: 'welcome',
  DISCONNECT: 'disconnect',
  REJECT_SUBSCRIPTION: 'reject_subscription',
  CONFIRM_SUBSCRIPTION: 'confirm_subscription',
} as const;

export const WS_CHANNELS = {
  STATISTIC_EXPORTS: JSON.stringify({channel: 'StatisticExportNotificationChannel'}),
  DATA_TABS_EXPORTS: JSON.stringify({channel: 'DataTabExportsNotificationChannel'}),
  COUPON_TOKENS: JSON.stringify({channel: 'GenerateCouponTokensNotificationChannel'}),
} as const;
