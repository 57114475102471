import React from 'react';

import classNames from 'classnames';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

import {TranslationJsx} from 'client/models/language/types';

import Icon from '../../../icon';

function NavigationItem(props) {
  const {
    name = '',
    children = '',
    iconName = '',
    colorTheme = '',
    className = '',
    id = '',
    opened = false,
    dataNavItem = '',
    activeClassName = 'navigation__item--active',
    to = null,
    exact = false,
    disabled = false,
    languageState,
    externalLink = null,
    disabledTooltip = null,
    isActive,
  } = props;

  const LANGUAGE = languageState.payload.NAVIGATION;

  const theme = colorTheme.trim();
  let cssClass = '';
  let content = null;

  const tooltipText = isNull(disabledTooltip) ? LANGUAGE.SECTION_AVAILABLE_SOON : disabledTooltip;
  const title = disabled ? tooltipText : null;

  if (to !== null && !disabled) {
    cssClass = classNames({
      navigation__item: true,
      [`navigation__item--theme-${theme}`]: !!theme,
    });

    content = (
      <NavLink
        to={to}
        id={id}
        exact={exact}
        data-nav-item={dataNavItem}
        className={`${cssClass} ${className}`}
        activeClassName={activeClassName}
      >
        <div className="navigation__link" title={title}>
          <div className="navigation__icon-wrap">
            {iconName && <Icon className="navigation__icon" name={iconName} />}
          </div>
          <span className="navigation__link-name">{name}</span>
        </div>
      </NavLink>
    );
  } else {
    cssClass = classNames({
      navigation__item: true,
      [activeClassName]: opened || isActive,
      [`navigation__item--theme-${theme}`]: !!theme,
      'navigation__item--disabled': disabled,
      'navigation__item--has-children': !!children,
    });

    const onClick = externalLink ? () => window.open(externalLink, '_blank') : null;

    content = (
      <div
        id={id}
        data-nav-item={dataNavItem}
        data-nav-item-opened={opened ? 'true' : 'false'}
        className={`${cssClass} ${className}`}
      >
        <div className="navigation__link" title={title} onClick={onClick}>
          <div className="navigation__icon-wrap">
            {iconName && <Icon className="navigation__icon" name={iconName} />}
          </div>
          <span className="navigation__link-name">{name}</span>
          <Icon className="navigation__icon-plus" name={opened ? 'collapse' : 'expand'} />
        </div>
        <div className="navigation__subnav">{children}</div>
      </div>
    );
  }

  return content;
}

NavigationItem.propTypes = {
  name: TranslationJsx,
  iconName: PropTypes.string,
  children: PropTypes.any,
  colorTheme: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  opened: PropTypes.bool,
  dataNavItem: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
  exact: PropTypes.bool,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  languageState: PropTypes.object.isRequired,
  externalLink: PropTypes.string,
  disabledTooltip: TranslationJsx,
};

export default connect((state) => ({
  languageState: state.languageState,
}))(NavigationItem);
