import sortBy from 'lodash/sortBy';

export const mapNotWinnersData = (participations, wins) => {
  if (!participations) {
    return {
      percent: 0,
      number: 0,
    };
  }

  return {
    percent: Math.round(((participations - wins) / participations) * 100),
    number: participations - wins,
  };
};

export const mapWinningDevices = (data) => {
  if (!data) {
    return null;
  }

  const allDevices = data.map(({device}) => device);
  const uniqueDevices = [...new Map(allDevices.map((item) => [item.id, item])).values()];

  return sortBy(uniqueDevices, 'name');
};
