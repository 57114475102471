import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {CheckboxInput} from 'client/common/inputs';
import {CheckboxInputColorVariants} from 'client/common/inputs/checkbox-input';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {Translation} from 'client/models/language/types';

import cssModule from './check-list.module.scss';

const b = bem('check-list', {cssModule});

type CheckListOption = {
  label: Translation | React.ReactNode;
  value: string | number;
};

type CheckListProps = {
  className?: string;
  classNames?: {
    label?: string;
    allOption?: string;
    scrollContainer?: string;
  };
  name?: string;
  label?: Translation;
  options: CheckListOption[];
  onChange: (value: (string | number)[]) => any;
  checked: (string | number)[];
  singleCheck?: boolean;
  asLabel?: boolean;
  color?: (typeof CheckboxInputColorVariants)[number];
  inversionColor?: boolean;
  allOption?: boolean;
  allOptionLabel?: Translation;
  maxHeight?: number;
  bordered?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

const CheckList: React.FC<CheckListProps> = (props) => {
  const {
    className,
    options,
    onChange,
    checked,
    color,
    singleCheck,
    asLabel,
    inversionColor,
    allOption,
    allOptionLabel,
    maxHeight,
    bordered,
    label,
    classNames,
    name,
    disabled,
    readOnly,
  } = props;
  const lang = useLanguage('COMMON');

  const changeHandler = (value: string | number) => {
    if (singleCheck) {
      onChange([value]);
    } else {
      let nextChecked = [...checked];
      if (nextChecked.includes(value)) {
        nextChecked = nextChecked.filter((i) => i !== value);
      } else {
        nextChecked.push(value);
      }
      onChange(nextChecked);
    }
  };

  const allChecked = checked.length === options.length;
  const someChecked = !allChecked && checked.length > 0;

  const optionBlock = (
    <div className={b('options')}>
      {options?.map((item) => (
        <CheckboxInput
          name={item.value?.toString()}
          label={item.label}
          value={item.value}
          key={item.value}
          color={color}
          checked={checked.includes(item.value)}
          className={b('option')}
          onChange={() => changeHandler(item.value)}
          asLabel={asLabel}
          inversionColor={inversionColor}
          readOnly={readOnly}
          disabled={disabled}
        />
      ))}
    </div>
  );

  return (
    <div className={cn(b({bordered}), className)}>
      {label && (
        <label htmlFor={name} className={cn(b('label'), classNames?.label)}>
          {label}
        </label>
      )}
      {allOption && (
        <div className={cn(b('all-option'), classNames?.allOption)}>
          <CheckboxInput
            label={allOptionLabel || lang.SELECT_ALL}
            color={color}
            inversionColor={inversionColor}
            checked={allChecked}
            isPartiallyChecked={someChecked}
            readOnly={readOnly}
            disabled={disabled}
            onChange={() => {
              if (allChecked) {
                onChange([]);
              } else {
                onChange(options.map((i) => i.value));
              }
            }}
          />
        </div>
      )}
      <div className={b('options-wrap')}>
        {maxHeight ? (
          <CustomScrollbars
            className={classNames?.scrollContainer}
            scrollbarProps={{
              autoHeightMax: maxHeight,
            }}
          >
            {optionBlock}
          </CustomScrollbars>
        ) : (
          optionBlock
        )}
      </div>
    </div>
  );
};

export default CheckList;
