import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';

import cssModule from './radio-button-group.module.scss';

const b = bem('radio-button-group', {cssModule});

const RadioButtonGroup = (props) => {
  const {
    legend = null,
    name,
    radioButtons,
    onChange,
    className = '',
    controlClassName = '',
    labelClassName = '',
    disabled = false,
    value: fieldsetValue,
  } = props;

  return (
    <fieldset className={cn(b(), className)} onChange={onChange}>
      {legend && <legend className={b('legend')}>{legend}</legend>}

      <div className={cn(b('control'), controlClassName)}>
        {radioButtons.map(({value, label}) => (
          <div className={b('radio-button')} key={value}>
            <input
              type="radio"
              id={`${name}-${value}`}
              value={value}
              name={name}
              className={b('radio-input')}
              disabled={disabled}
              checked={value === fieldsetValue}
              readOnly
            />
            <label htmlFor={`${name}-${value}`} className={cn(b('radio-label', {disabled}), labelClassName)}>
              <span className={b('radio-value')}>{label}</span>
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

RadioButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  radioButtons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.oneOfType([PropTypes.element, TranslationJsx]).isRequired,
    }),
  ).isRequired,
  legend: TranslationJsx,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  className: PropTypes.string,
  controlClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default RadioButtonGroup;
