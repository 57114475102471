import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {ensuredForwardRef} from 'react-use';

import bem from 'client/services/bem';
import {blobToBase64} from 'client/services/blobToBase64';

import AppButton, {AppButtonProps} from 'client/common/buttons/app-button';

import './file-button.scss';

const b = bem('file-button');

/**
 *
 * @type {React.FC<FileButton.PropTypes>}
 */

// TODO: from jsx => tsx

const FileButton = ensuredForwardRef((props, ref) => {
  const {
    name = 'add-file',
    onChange = null,
    onClick = null,
    className = '',
    acceptFormats = '',
    base64 = false,
    ...button
  } = props;

  const handleClick = () => {
    if (onClick) {
      onClick(ref);
    } else {
      ref.current.click();
    }
  };

  const onClickHandler = (e) => {
    e.target.value = '';
  };

  const onChangeHandler = async (e) => {
    const nativeFile = e.target.files[0];
    if (nativeFile) {
      const file = base64 ? await blobToBase64(nativeFile) : nativeFile;
      onChange(file, nativeFile.name);
    }
  };

  return (
    <React.Fragment>
      <AppButton {...button} className={cn(className, b('button'))} onClick={handleClick} />
      <input
        ref={ref}
        name={name}
        type="file"
        onChange={onChangeHandler}
        onClick={onClickHandler}
        className={b('input')}
        accept={acceptFormats}
      />
    </React.Fragment>
  );
});

FileButton.propTypes = {
  ...AppButtonProps,
  base64: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  acceptFormats: PropTypes.string,
};

export default FileButton;
