export const GAME_TYPES = {
  INSTANT_WIN: 'instant_win',
  PRIZE_DRAW: 'prize_draw',
  ATTRIBUTION_STEP: 'attribution_step',
} as const;

export const PRIZE_SEND_STATES = {
  SET_FOR_ALL: 'set_for_all',
  SET_FOR_SEVERAL: 'set_for_several',
  NOT_SET: 'not_set',
} as const;

export const PRIZE_TYPES = {
  COUPON: 'CouponPrize',
  LOYALTY: 'LoyaltyPrize',
  COMMON: 'Prize',
} as const;

export const COUPON_TYPES = {
  WEEZIO: 'WeezioCoupon',
  SOGEC: 'SogecCoupon',
  APP: 'AppCoupon',
  CLIENT: 'ClientCoupon',
  COMMON: 'CommonCodeCoupon',
} as const;

export const COUPON_TOKEN_MODES = {
  PERMANENT: 'permanent',
  MOMENT_OF_WINNING: 'moment_of_winning',
  MOMENT_OF_USING: 'moment_of_using',
};

export const PRIZE_MAP_TYPES = {
  ONLINE: 'OnlinePrizeMap',
  HYBRID: 'HybridPrizeMap',
  NONE_LEVEL: 'NoneLevelPrizeMap',
} as const;

export const PRIZE_GEO_LEVEL = {
  GLOBAL: 'global',
  DEVICE: 'device',
  PLACE: 'place',
  PLAN: 'plan',
  NONE: 'none',
} as const;

export const PRIZE_ONLINE_LEVEL = {
  GLOBAL: 'global',
  SOURCE: 'source',
  CAMPAIGN: 'campaign',
  NONE: 'none',
} as const;

export const PRIZE_LEVEL_METHODS = {
  SUPERIOR: '>',
  SUPERIOR_OR_EQUAL: '>=',
  INFERIOR_OR_EQUAL: '<=',
  EQUAL: '=',
  NOT_EQUAL: '!=',
} as const;
