import {useCallback} from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {setViewModeUserId, setViewModeSettings} from 'client/ducks/user/actions';
import {selectViewMode} from 'client/ducks/user/selectors';

import {ViewMode, ViewModeSettings} from './types';

export default () => {
  const dispatch = useDispatch();
  const viewMode: ViewMode = useSelector(selectViewMode);

  const setUserId = useCallback((id: number) => dispatch(setViewModeUserId(id)), [dispatch]);

  const setSettings = useCallback((settings: ViewModeSettings) => dispatch(setViewModeSettings(settings)), [dispatch]);

  const toggleSetting = useCallback(
    (settingName: string) => {
      dispatch(setViewModeSettings({[settingName]: !viewMode.settings[settingName as keyof typeof viewMode.settings]}));
    },
    [dispatch, viewMode],
  );

  return {
    viewMode,
    setViewModeUserId: setUserId,
    setViewModeSettings: setSettings,
    toggleViewModeSetting: toggleSetting,
  };
};
