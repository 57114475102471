import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

import {CATALOG_TYPES} from './constants';
import {getFormItems} from './mappers';
import {filterByCatalogType} from './mappers';
import types from './types';

const initialState = {
  templates: [],
  clientTemplates: [],
  clientTemplateNames: [],
  template: {
    form: {
      values: {
        name: '',
        form_pages: [],
      },
      initialFormItems: {},
      modifiedFormItems: {},
      errors: {},
    },
  },

  // virgin templates
  generalTemplates: [],
  formTemplates: [],
  gameTemplates: [],
  quizTemplates: [],

  // client templates
  generalClientTemplates: [],
  formClientTemplates: [],
  gameClientTemplates: [],
  quizClientTemplates: [],

  clientFonts: null,

  // results
  result: null,
  initialResult: null,
  fetchingResult: false,

  // for path editor
  operationTemplates: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_TEMPLATES_SUCCESS: {
      const templates = action.payload.templates;

      return {
        ...state,
        templates,
        generalTemplates: filterByCatalogType(templates, CATALOG_TYPES.GENERAL),
        formTemplates: filterByCatalogType(templates, CATALOG_TYPES.FORM),
        gameTemplates: filterByCatalogType(templates, CATALOG_TYPES.GAME),
        quizTemplates: filterByCatalogType(templates, CATALOG_TYPES.QUIZ),
      };
    }

    case types.GET_CLIENT_TEMPLATES_SUCCESS: {
      const templates = action.payload.client_templates;

      return {
        ...state,
        clientTemplates: templates,
        generalClientTemplates: filterByCatalogType(templates, CATALOG_TYPES.GENERAL),
        formClientTemplates: filterByCatalogType(templates, CATALOG_TYPES.FORM),
        gameClientTemplates: filterByCatalogType(templates, CATALOG_TYPES.GAME),
        quizClientTemplates: filterByCatalogType(templates, CATALOG_TYPES.QUIZ),
      };
    }

    case types.GET_CLIENT_TEMPLATE_NAMES_SUCCESS: {
      return {
        ...state,
        clientTemplateNames: action.payload.client_template_names.map((i) => i.toLowerCase()),
      };
    }

    case types.GET_TEMPLATE_FORM_SUCCESS:
      return {
        ...state,
        template: {
          ...state.template,
          form: {
            ...state.template.form,
            values: {
              ...action.payload.form,
              form_pages: action.payload.form.form_pages,
            },
            initialFormItems: getFormItems(action.payload.form?.form_pages),
          },
        },
      };

    case types.GET_RESULT_BY_INTERFACE_REQUEST:
    case types.GET_RESULT_BY_CLIENT_TEMPLATE_REQUEST:
      return {
        ...state,
        fetchingResult: true,
      };

    case types.GET_RESULT_BY_INTERFACE_SUCCESS:
    case types.GET_RESULT_BY_CLIENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        initialResult: action.payload,
        result: action.payload,
        fetchingResult: false,
      };

    case types.GET_RESULT_BY_INTERFACE_ERROR:
    case types.GET_RESULT_BY_CLIENT_TEMPLATE_ERROR:
      return {
        ...state,
        fetchingResult: false,
      };

    case types.UPDATE_RESULT:
      return {
        ...state,
        result: action.payload,
      };

    case types.RESET_RESULT:
      return {
        ...state,
        result: state.initialResult,
      };

    case types.SAVE_RESULT:
      return {
        ...state,
        initialResult: state.result,
      };

    case types.CLEAR_RESULT:
      return {
        ...state,
        result: null,
        initialResult: null,
        fetchingResult: false,
      };

    case types.CLEAR_CURRENT_TEMPLATE_FORM:
      return {
        ...state,
        template: {
          form: {
            values: {
              name: '',
              form_pages: [],
            },
            initialFormItems: {},
            modifiedFormItems: {},
            errors: {},
          },
        },
      };

    case types.SET_CURRENT_TEMPLATE_FORM_ERROR:
      const {formPageId, formSectionId, formItemId, err} = action.payload;
      const currentTemplateFormErrors = {
        ...state.template.form.errors,
      };

      set(currentTemplateFormErrors, `'${formPageId}'.'${formSectionId}'.'${formItemId}'`, err);
      const currentTemplateFormErrorsState = {...state};
      set(currentTemplateFormErrorsState, 'template.form.errors', currentTemplateFormErrors);
      return currentTemplateFormErrorsState;

    case types.SET_CURRENT_TEMPLATE_FORM_ANSWER:
      const formItem = action.payload;
      const currentTemplateFormAnswers = {
        ...state.template.form.modifiedFormItems,
        ...formItem,
      };
      const currentTemplateFormAnswersState = {...state};
      set(currentTemplateFormAnswersState, 'template.form.modifiedFormItems', currentTemplateFormAnswers);
      return currentTemplateFormAnswersState;

    case types.GET_CLIENT_FONTS_SUCCESS:
      return {
        ...state,
        clientFonts: action.payload.fonts,
      };

    case types.GET_OPERATION_TEMPLATES_SUCCESS: {
      const data = action.payload.operation_templates.filter((template) => {
        return template.catalog?.out_path || (template.row !== null && template.position !== null);
      });

      const {outPathPages, pathPages} = data.reduce(
        (acc, template) => {
          const updatedTemplate = {
            ...template,
            row: template.catalog?.out_path ? 0 : Math.max((template.row ?? 1) - 1, 0),
            position: Math.max((template.position ?? 1) - 1, 0),
          };

          if (template.catalog?.out_path) {
            acc.outPathPages.push(updatedTemplate);
          } else {
            acc.pathPages.push(updatedTemplate);
          }

          return acc;
        },
        {outPathPages: [], pathPages: []},
      );

      return {
        ...state,
        operationTemplates: {outPathPages, pathPages},
      };
    }

    case types.COPY_CLIENT_TEMPLATE_TO_PATH_SUCCESS: {
      const template = action.payload.operation_template;
      const keyPages = template.catalog?.out_path ? 'outPathPages' : 'pathPages';
      state.operationTemplates[keyPages]?.push({...template, row: template.row - 1, position: template.position - 1});
      state.operationTemplates[keyPages] = [...state.operationTemplates[keyPages]];
      return state;
    }

    case types.MODIFY_OPERATION_TEMPLATES_PATH_SUCCESS: {
      const templates = action.payload.operation_templates;
      if (templates[0]) {
        const keyPages = templates[0].catalog?.out_path ? 'outPathPages' : 'pathPages';
        const oldPages = state.operationTemplates[keyPages];
        templates.forEach((template) => {
          const oldIndex = oldPages.findIndex((oldTemplate) => oldTemplate.id === template.id);
          const parsedTemplate = {...template, row: template.row - 1, position: template.position - 1};
          if (oldIndex !== -1) {
            oldPages[oldIndex] = parsedTemplate;
          } else {
            oldPages.push(parsedTemplate);
          }
        });
        state.operationTemplates[keyPages] = cloneDeep(oldPages);
      }
      return state;
    }

    case types.DELETION_OPERATION_TEMPLATES_PATH_SUCCESS: {
      const templates = action.payload.operation_templates;

      if (!templates || templates.length === 0) {
        return state;
      }

      const keyPages = templates[0].catalog?.out_path ? 'outPathPages' : 'pathPages';

      return {
        ...state,
        operationTemplates: {
          ...state.operationTemplates,
          [keyPages]: templates.map((template) => ({
            ...template,
            row: template.row - 1,
            position: template.position - 1,
          })),
        },
      };
    }

    case types.REMOVE_TEMPLATE_FROM_PATH_SUCCESS: {
      const templateId = action.payload?.id;
      const data = [...state.operationTemplates.pathPages, ...state.operationTemplates.outPathPages];
      const template = data.find((t) => t.id === templateId);

      const keyPages = template.catalog?.out_path ? 'outPathPages' : 'pathPages';

      if (!templateId) {
        return {
          ...state,
          operationTemplates: state.operationTemplates,
        };
      }

      return {
        ...state,
        operationTemplates: {
          ...state.operationTemplates,
          [keyPages]: state.operationTemplates[keyPages].filter((t) => t.id !== templateId),
        },
      };
    }

    default:
      return state;
  }
}
