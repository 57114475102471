import isNumber from 'lodash/isNumber';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import {updateInArray, removeFromArray} from 'client/services/helpers';

import {mapLinkedInterfaces} from './mappers';
import types from './types';

import {SOURCE_TYPES} from '../../components/autotask-scenario/components/forms/ats-var-form/ats-var-form';
import internalColumnsTypes from '../internal-columns/types';

const initialState = {
  type: types.NO_DATA,
  payload: {
    automation_task: {
      interfaces: [],
    },
    submitError: false,
    submitErrorMsg: '',
    scenario_variables: [],
    primary_scenarios: [],
    next_scenarios: [],
    scenario_steps: [],
    scenario_execution_logs: [],
    trigger_mapping_items: [],
    linked_interfaces: [],
  },
  possibleTestVariables: [],
  scenario_variable_options: {
    [SOURCE_TYPES.interface]: [],
    [SOURCE_TYPES.database]: [],
    [SOURCE_TYPES.variable]: [],
  },
  list: [],
  prizes: [],
  columnAdapters: {},
};

const nameIdSelectFormatter = (i) => ({
  label: i.full_name || i.name,
  value: i.id,
});

export default function (_state = initialState, action) {
  let state = {..._state};

  switch (action.type) {
    case types.NO_DATA:
      state.type = types.NO_DATA;
      state.payload = {...initialState.payload};
      return state;

    case types.LOADED:
      state.type = types.LOADED;
      state.payload = {...state.payload, ...action.payload.scenario};
      state.scenario_variable_options = {
        ...state.scenario_variable_options,
        [SOURCE_TYPES.interface]:
          action.payload && action.payload.scenario && action.payload.scenario.automation_task
            ? action.payload.scenario.automation_task.interfaces.map((v) => ({
                label: v.name,
                value: v.id,
                additionalOptions: v.interface_items.map(nameIdSelectFormatter),
              }))
            : [],
        [SOURCE_TYPES.variable]:
          action.payload && action.payload.scenario && action.payload.scenario.primary_scenarios
            ? action.payload.scenario.primary_scenarios.map((v) => ({
                label: v.name,
                value: v.id,
                additionalOptions: v.scenario_variables
                  .map(nameIdSelectFormatter)
                  .concat(v.scenario_step_variables?.map(nameIdSelectFormatter) || []),
              }))
            : [],
      };

      state.payload.linked_interfaces = mapLinkedInterfaces(action.payload.scenario);

      return state;

    case types.UPDATED:
      state.type = types.UPDATED;
      state.payload = {...state.payload, ...action.payload.scenario};
      return state;

    case types.DELETED:
      state.type = types.NO_DATA;
      state.payload = {...initialState.payload};
      return state;

    case types.VARIABLE_LIST_LOADED:
      state.type = types.LOADED;
      state.payload = {
        ...state.payload,
        scenario_variables: action.payload.scenario_variables,
      };
      return state;

    case types.VARIABLE_CREATED:
      state.type = types.VARIABLE_CREATED;
      state.payload = {
        ...state.payload,
        scenario_variables: [...state.payload.scenario_variables, action.payload.scenario_variable],
      };
      return state;

    case types.VARIABLE_UPDATED:
      state.type = types.VARIABLE_UPDATED;
      state.payload = {
        ...state.payload,
        scenario_variables: updateInArray(state.payload.scenario_variables, action.payload.scenario_variable),
      };
      return state;

    case types.VARIABLE_DELETED:
      state.type = types.VARIABLE_UPDATED;
      state.payload = {
        ...state.payload,
        scenario_variables: removeFromArray(state.payload.scenario_variables, action.meta.id),
      };
      return state;

    case types.STEP_LIST_LOADED:
      state.type = types.LOADED;
      state.payload = {
        ...state.payload,
        scenario_steps: [...action.payload.scenario_steps],
      };
      return state;

    case types.STEP_ADD_INTO_STORE:
      state.type = types.LOADED;
      state.payload = {
        ...state.payload,
        scenario_steps: [...state.payload.scenario_steps, action.payload],
      };
      return state;

    case types.STEP_SORT_IN_STORE: {
      const {oldIndex, newIndex, startPosition} = action.payload;
      const steps =
        newIndex > oldIndex
          ? [
              ...state.payload.scenario_steps.slice(0, oldIndex),
              ...state.payload.scenario_steps.slice(oldIndex + 1, newIndex + 1),
              state.payload.scenario_steps[oldIndex],
              ...state.payload.scenario_steps.slice(newIndex + 1),
            ]
          : [
              ...state.payload.scenario_steps.slice(0, newIndex),
              state.payload.scenario_steps[oldIndex],
              ...state.payload.scenario_steps.slice(newIndex, oldIndex),
              ...state.payload.scenario_steps.slice(oldIndex + 1),
            ];

      state.payload = {
        ...state.payload,
        scenario_steps: steps.map((step, index) => ({
          ...step,
          position: startPosition + index,
        })),
      };

      return state;
    }

    case types.GET_POSSIBLE_TEST_VARIABLES_SUCCESS:
      state.possibleTestVariables = action.payload.scenario_variables;

      return state;

    case internalColumnsTypes.GET_INTERNAL_COLUMNS_SUCCESS:
      const options = {};

      action.payload.column_adapters.forEach((v) => {
        if (v.record_type === 'Participation') {
          // Participation column adapters will be received in separate request,
          // see GET_PARTICIPATION_COLUMN_ADAPTERS_SUCCESS
          return;
        }

        const recordType = ['VisualColumnAdapter', 'ParticipationPrizeColumnAdapter'].includes(v.type)
          ? v.record_type + v.db_number
          : v.record_type;

        if (!options[recordType]) {
          options[recordType] = [];
        }

        options[recordType].push({label: v.name, value: v.id});
      });

      let nextDatabaseOptions = uniqBy(
        [
          ...state.scenario_variable_options[SOURCE_TYPES.database],
          ...Object.keys(options).map((v) => ({
            label: v,
            value: v,
            additionalOptions: options[v],
          })),
        ],
        'value',
      );

      state.scenario_variable_options = {
        ...state.scenario_variable_options,
        [SOURCE_TYPES.database]: sortBy(nextDatabaseOptions, 'label'),
      };
      // state.scenario_variable_options = sortBy(state.scenario_variable_options, 'label');

      return state;

    case types.GET_PARTICIPATION_COLUMN_ADAPTERS_SUCCESS:
      const data = action?.payload?.Participation || [];

      const newDatabaseOptions = [
        ...state.scenario_variable_options[SOURCE_TYPES.database],
        {
          label: 'Participation',
          value: 'Participation',
          additionalOptions: sortBy(
            data.map(({id, name}) => ({
              value: id,
              label: name,
            })),
            'label',
          ),
        },
      ];

      state.scenario_variable_options = {
        ...state.scenario_variable_options,
        [SOURCE_TYPES.database]: sortBy(newDatabaseOptions, 'label'),
      };
      return state;

    case types.STEP_CREATED:
      state.type = types.STEP_CREATED;
      state.payload = {
        ...state.payload,
        scenario_steps: updateInArray(state.payload.scenario_steps, action.payload.scenario_step, action.meta.id),
      };
      return state;

    case types.STEP_UPDATED:
      state.type = types.STEP_UPDATED;
      state.payload = {
        ...state.payload,
        scenario_steps: updateInArray(state.payload.scenario_steps, action.payload.scenario_step),
      };
      return state;

    case types.STEP_DELETED:
      state.type = types.STEP_CREATED;
      state.payload = {
        ...state.payload,
        scenario_steps: removeFromArray(state.payload.scenario_steps, action.meta.id),
      };
      return state;

    case types.EXECUTION_LOG_LIST_LOADED:
      state.type = types.EXECUTION_LOG_LIST_LOADED;
      state.payload = {
        ...state.payload,
        scenario_execution_logs: [...action.payload.scenario_execution_logs],
      };
      return state;

    case types.LIST_LOADED:
      state.list = [...action.payload.scenarios];
      return state;

    case types.STEP_LIST_REMOVE_NOT_SAVED:
      state.payload = {
        ...state.payload,
        scenario_steps: state.payload.scenario_steps.filter((v) => isNumber(v.id)),
      };
      return state;

    case types.TEST_SCENARIO_SUCCESS:
      state.payload.submitError = false;
      state.payload.submitErrorMsg = '';
      return state;

    case types.TEST_SCENARIO_ERROR:
      state.payload.submitError = true;
      state.payload.submitErrorMsg = action.error.message;
      return state;

    case types.GET_PRIZES_SUCCESS:
      state.prizes = action.payload?.participation_prizes?.available_prize_names;
      return state;

    case types.GET_COLUMN_ADAPTERS_SUCCESS:
      state.columnAdapters = action.payload;
      return state;

    default:
      return state;
  }
}
