import React from 'react';

import {Route} from 'routes/types';

import {CLIENT_PAGES, PAGES_BACKGROUND_COLORS} from 'client/common/config';

const AuthPage = React.lazy(() => import('client/pages/auth'));
const SSOCallbackPage = React.lazy(() => import('client/pages/sso-callback-page'));

export default [
  {
    component: AuthPage,
    name: 'AuthPage',
    path: CLIENT_PAGES.LOGIN,
    params: {
      isNotNeedLogin: true,
      nobreadcrumbs: true,
    },
  } as Route<'AuthPage'>,
  {
    component: AuthPage,
    name: 'AuthPageAushan',
    path: CLIENT_PAGES.LOGIN_AUCHAN,
    params: {
      isNotNeedLogin: true,
      nobreadcrumbs: true,
    },
  } as Route<'AuthPageAushan'>,
  {
    component: SSOCallbackPage,
    name: 'SSOCallbackPage',
    path: CLIENT_PAGES.SSO_CALLBACK,
    params: {
      background: PAGES_BACKGROUND_COLORS.LOGIN,
      isNotNeedLogin: true,
      nobreadcrumbs: true,
    },
  } as Route<'SSOCallbackPage'>,
  {
    component: AuthPage,
    name: 'AuthPageDoubleFactor',
    path: CLIENT_PAGES.DOUBLE_FACTOR_AUTH,
    params: {
      isNotNeedLogin: true,
      nobreadcrumbs: true,
    },
  } as Route<'AuthPageDoubleFactor'>,
  {
    component: AuthPage,
    name: 'AuthPageSignUp',
    path: CLIENT_PAGES.SIGN_UP,
    params: {
      isNotNeedLogin: true,
      nobreadcrumbs: true,
    },
  } as Route<'AuthPageSignUp'>,
  {
    component: AuthPage,
    name: 'AuthPagePasswordRecovery',
    path: CLIENT_PAGES.PASSWORD_RECOVERY,
    params: {
      background: PAGES_BACKGROUND_COLORS.LOGIN,
      isNotNeedLogin: true,
      nobreadcrumbs: true,
    },
  } as Route<'AuthPagePasswordRecovery'>,
  {
    component: AuthPage,
    name: 'AuthPagePasswordChange',
    path: CLIENT_PAGES.PASSWORD_CHANGE,
    params: {
      isNotNeedLogin: true,
      nobreadcrumbs: true,
    },
  } as Route<'AuthPagePasswordChange'>,
];
