import React from 'react';

import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {TranslationJsx} from 'client/models/language/types';

import './breadcrumbs.scss';

function Breadcrumbs({
  links = [
    {
      name: '',
      href: '',
      className: '',
    },
  ],
}) {
  return (
    <ul className="breadcrumbs">
      {links
        .filter((item) => !item.hidden)
        .map((item, i) => {
          return (
            <li className={`breadcrumbs__item ${item.className || ''}`} key={i}>
              {item.href ? (
                <Link to={item.href} className="breadcrumbs__link" title={item.name}>
                  {item.name}
                </Link>
              ) : (
                <span className="breadcrumbs__link" title={item.name}>
                  {item.name}
                </span>
              )}
              <span className="icon-new-chevron breadcrumbs__icon" />
            </li>
          );
        })}
    </ul>
  );
}

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: TranslationJsx,
      href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      className: PropTypes.string,
    }),
  ),
};

export default Breadcrumbs;
