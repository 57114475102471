import React, {useState} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import AppButton, {AppButtonProps, ButtonColorType} from 'client/common/buttons/app-button';
import MessageModal from 'client/common/modals/message-modal';
import Modal from 'client/common/modals/modal';

import {TranslationJsx} from 'client/models/language/types';

import './confirmation-modal.scss';

const b = bem('confirmation-modal');

let timeout = 0;

/**
 *
 * @type {React.FC<InferProps<typeof ConfirmationModal.propTypes>>}
 */

// TODO: from jsx => tsx
const ConfirmationModal = (props) => {
  const {
    show = false,
    onClose = null,
    onConfirm = null,
    onCancel = null,
    title = '',
    subtitle = '',
    message = '',
    cancelText = '',
    confirmText = '',
    confirmMessage = '',
    className = '',
    classNames = {},
    errorMessage = '',
    buttonCancelClass = 'button--bg-5',
    buttonConfirmClass = 'button--bg-1',
    buttonCancelDisabled = false,
    buttonConfirmDisabled = false,
    buttonCancelColor = 'clients',
    buttonConfirmColor = 'clients',
    buttonCancel = {},
    buttonConfirm = {},
    clientSide = false,
    messageClassName = '',
    adminBackground = false,
    confirmationCloseDelay = 3000,
    children,
  } = props;

  const [showMessage, setShowMessage] = useState(false);

  const isAdminSide = !clientSide;
  const isClientSide = clientSide;

  // classes for admin side
  const buttonClass = isAdminSide ? 'button modal-window__footer-btn' : '';
  const buttonCancelClasses = `${buttonClass} ${isAdminSide ? buttonCancelClass : ''}`;
  const buttonConfirmClasses = `${buttonClass} ${isAdminSide ? buttonConfirmClass : ''}`;
  const buttonSize = isAdminSide ? null : 'small';
  const textClass = isAdminSide ? 'main-text' : b('main-text');

  const closeModalMessage = () => {
    setShowMessage(false);
    if (onClose) {
      onClose?.();
    }
  };

  const handleConfirm = () => {
    onConfirm?.();
    if (confirmMessage) {
      setShowMessage(true);
      if (confirmationCloseDelay) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          closeModalMessage();
          clearTimeout(timeout);
        }, confirmationCloseDelay);
      }
    }
  };

  const hasMessage = !!children || !!subtitle || !!message || !!errorMessage;

  const {body: bodyClassName, content: contentClassName, ...restClassNames} = classNames;

  return (
    <React.Fragment>
      <Modal
        show={show && !showMessage}
        className={cn(b({'client-side': isClientSide}), className)}
        onClose={onClose}
        title={title}
        classNames={{
          body: cn(b('body', {'admin-side': adminBackground}), bodyClassName),
          content: cn(b('content', [title ? 'with-title' : '']), contentClassName),
          ...restClassNames,
        }}
      >
        {hasMessage && (
          <div className={cn(messageClassName, b('message'))}>
            {subtitle && <p className={textClass}>{subtitle}</p>}
            {message && <p className={textClass}>{message}</p>}
            {children}
            {errorMessage && <p className={cn(b('main-text', ['error']))}>{errorMessage}</p>}
          </div>
        )}
        <div className={b('buttons')}>
          {(cancelText || buttonCancel?.label) && (
            <AppButton
              label={cancelText}
              className={buttonCancelClasses}
              disabled={buttonCancelDisabled}
              onClick={onCancel || onClose}
              nativeStyle={isAdminSide}
              color={buttonCancelColor}
              transparent={isClientSide}
              link={isClientSide}
              size={buttonSize}
              {...buttonCancel}
            />
          )}
          {(confirmText || buttonConfirm?.label) && (
            <AppButton
              label={confirmText}
              className={buttonConfirmClasses}
              disabled={buttonConfirmDisabled}
              onClick={handleConfirm}
              nativeStyle={isAdminSide}
              color={buttonConfirmColor}
              size={buttonSize}
              {...buttonConfirm}
            />
          )}
        </div>
      </Modal>
      {!!confirmMessage && <MessageModal onClose={closeModalMessage} show={showMessage} message={confirmMessage} />}
    </React.Fragment>
  );
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onConfirmMessage: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  message: PropTypes.node,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  errorMessage: PropTypes.node,
  cancelText: PropTypes.node,
  confirmText: PropTypes.node,
  confirmMessage: TranslationJsx,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
    content: PropTypes.string,
    close: PropTypes.string,
    title: PropTypes.string,
  }),
  messageClassName: PropTypes.string,
  buttonCancelClass: PropTypes.string,
  buttonConfirmClass: PropTypes.string,
  buttonConfirmDisabled: PropTypes.bool,
  buttonCancelDisabled: PropTypes.bool,
  buttonCancelColor: ButtonColorType,
  buttonConfirmColor: ButtonColorType,

  buttonConfirm: PropTypes.shape(AppButtonProps),
  buttonCancel: PropTypes.shape(AppButtonProps),
  clientSide: PropTypes.bool,
  adminBackground: PropTypes.bool,
  confirmationCloseDelay: PropTypes.number,
};

export default ConfirmationModal;
