export const setIsLoading = (state, isLoading) => ({
  ...state,
  isLoading,
});

export const updateArrayElement = (array, elementData, idProp = 'id') => {
  const elementIndex = array.findIndex((el) => el[idProp] === elementData[idProp]);
  if (elementIndex === -1) {
    return array;
  }

  return [...array.slice(0, elementIndex), elementData, ...array.slice(elementIndex + 1)];
};

export const updateTranslation = (translationsById, translationData) => {
  const translation = translationsById[translationData.id];
  if (!translation) {
    return translationsById;
  }
  return {
    ...translationData,
    translation_items: translation.translation_items,
    translationData,
  };
};

export const addTranslationsItem = (translationsById, translationsItemData) => {
  const translation = translationsById[translationsItemData.translation_id];
  if (!translation) {
    return translationsById;
  }

  const translationsItems = translation.translation_items || [];
  translationsItems.push(translationsItemData);

  return {
    ...translation,
    translation_items: translationsItems,
  };
};

export const updateTranslationsItem = (translationsById, translationsItemData) => {
  const translation = translationsById[translationsItemData.translation_id];
  if (!translation) {
    return translationsById;
  }

  const translationsItems = translation.translation_items
    ?.map((item) => (item.id === translationsItemData.id ? translationsItemData : item))
    .filter(Boolean);

  return {
    ...translation,
    translation_items: translationsItems,
  };
};

export const updateLanguage = (languages, language) => {
  return updateArrayElement(languages, language);
};
