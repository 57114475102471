import React from 'react';

import PropTypes from 'prop-types';

import './loader.scss';

function LoadingSpinner(props) {
  const {className = '', cssModifier = '', loading = false, children = null} = props;
  const mainNode = typeof children !== 'string' ? children : <span>{children}</span>;

  return loading ? <div className={`loader ${className || cssModifier}`} /> : mainNode;
}

LoadingSpinner.propTypes = {
  cssModifier: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

export default LoadingSpinner;
