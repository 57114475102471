import React, {Component} from 'react';

import PropTypes from 'prop-types';
// import classNames from 'classnames';
import {Scrollbars} from 'react-custom-scrollbars';

import './custom-scrollbars.scss';

class CustomScrollbars extends Component {
  static defaultProps = {
    vertThumbCssClass: '',
    vertTrackCssClass: '',
    horThumbCssClass: '',
    horTrackCssClass: '',
    viewCssClass: '',
    cssModifier: '',
    children: '',
    style: {},
    scrollbarProps: {},
    getRef: () => {},
  };

  static propTypes = {
    cssModifier: PropTypes.string,
    className: PropTypes.string,
    vertThumbCssClass: PropTypes.string,
    vertTrackCssClass: PropTypes.string,
    horThumbCssClass: PropTypes.string,
    horTrackCssClass: PropTypes.string,
    viewCssClass: PropTypes.string,
    children: PropTypes.any,
    style: PropTypes.object,
    scrollbarProps: PropTypes.object,
    getRef: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.renderThumbVertical = this.renderThumbVertical.bind(this);
    this.renderTrackVertical = this.renderTrackVertical.bind(this);
    this.renderThumbHorizontal = this.renderThumbHorizontal.bind(this);
    this.renderTrackHorizontal = this.renderTrackHorizontal.bind(this);
    this.renderView = this.renderView.bind(this);
  }

  componentDidMount() {
    this.props.getRef(this.scrollbar);
  }

  renderThumbVertical(props) {
    return <div className={`custom-scrollbars__thumb-vert ${this.props.vertThumbCssClass}`} {...props} />;
  }

  renderTrackVertical(props) {
    return <div className={`custom-scrollbars__track-vert ${this.props.vertTrackCssClass}`} {...props} />;
  }

  renderThumbHorizontal(props) {
    return <div className={`custom-scrollbars__thumb-hor ${this.props.horThumbCssClass}`} {...props} />;
  }

  renderTrackHorizontal(props) {
    return <div className={`custom-scrollbars__track-hor ${this.props.horTrackCssClass}`} {...props} />;
  }

  renderView(props) {
    return <div className={`custom-scrollbars__inner ${this.props.viewCssClass}`} {...props} />;
  }

  render() {
    const {scrollbarProps, children, style, cssModifier, className} = this.props;

    const defaultProps = {
      autoHeight: true,
      ...scrollbarProps,
    };

    return (
      <Scrollbars
        ref={(scrollbar) => {
          this.scrollbar = scrollbar;
        }}
        className={`custom-scrollbars ${cssModifier} ${className || ''}`}
        {...defaultProps}
        renderView={this.renderView}
        renderThumbVertical={this.renderThumbVertical}
        renderTrackVertical={this.renderTrackVertical}
        renderThumbHorizontal={this.renderThumbHorizontal}
        renderTrackHorizontal={this.renderTrackHorizontal}
        style={style}
      >
        {children}
      </Scrollbars>
    );
  }
}

export default CustomScrollbars;
