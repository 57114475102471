import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import {MEDIA_MIME_TYPES} from 'client/common/config';

import cssModule from './media-player.module.scss';

const b = bem('media-player', {cssModule});

type Source = {
  src: string;
  type: string;
};

const componentElementMap: {[key: string]: keyof React.JSX.IntrinsicElements} = {
  [MEDIA_MIME_TYPES.mp3]: 'audio',
  [MEDIA_MIME_TYPES.mp4]: 'video',
  [MEDIA_MIME_TYPES.pdf]: 'iframe',
};

type PlayerProps = {
  sources: Source[];
  mediaType: string;
  className?: string;
  width?: string | number;
  height?: string | number;
  keepRatio?: boolean;
  style?: React.CSSProperties;
  onLoadStart?: () => void;
};

const Player: React.FC<PlayerProps> = (props) => {
  const {mediaType, sources, className = '', width = 0, height = 0, keepRatio = true, style, onLoadStart} = props;
  const Element = componentElementMap[mediaType];

  if (!Element) {
    return null;
  }

  if (mediaType === MEDIA_MIME_TYPES.pdf) {
    return (
      <iframe
        className={cn(b('', {'keep-ratio': keepRatio}), className)}
        src={sources[0].src}
        width={width || 300}
        height={height || 300}
        style={style}
      />
    );
  }

  return (
    <Element controls style={style} className={cn(b(), className)} onLoadStart={onLoadStart}>
      {sources.map((source, index) => (
        <source key={index} src={source.src} type={source.type} />
      ))}
    </Element>
  );
};

export default Player;
