import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';

import './field-wrap.scss';

const b = bem('field-wrap');

/**
 * @type {React.FC<FieldWrap.propTypes>}
 */

const FieldWrap = ({
  label = '',
  title = '',
  wrap = false,
  center = false,
  full = false,
  children,
  className = '',
  ...rest
}) => (
  <div className={cn(b({wrap, center, full}), className)} {...rest}>
    {title && <p className={b('title')}>{title}</p>}
    {label && <p className={b('label')}>{label}</p>}
    {children}
  </div>
);

FieldWrap.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: TranslationJsx,
  label: TranslationJsx,
  wrap: PropTypes.bool,
  center: PropTypes.bool,
  full: PropTypes.bool,
};

export default FieldWrap;
