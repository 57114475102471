import React, {useState} from 'react';

import moment from 'moment';
import Datetime, {TimeConstraints} from 'react-datetime';
import {IMask, IMaskInput} from 'react-imask';

import bem from 'client/services/bem';

import InputWrapper, {InputWrapperProps} from 'client/common/inputs/input-wrapper';

import 'react-datetime/css/react-datetime.css';

import cssModule from './time-input.module.scss';

const b = bem('time-input', {cssModule});

type Props = InputWrapperProps & {
  withSeconds?: boolean;
  onChange?: (time: string) => void;
  onBlur?: () => void;
  pattern?: string;
  disabled?: boolean;
  timeConstraints?: TimeConstraints;
};

const TimeInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className = '',
    label = '',
    errorMessage = '',
    warningMessage = '',
    withSeconds = false,
    onChange,
    value: initialValue,
    pattern,
    timeConstraints = {},
    required = false,
    onBlur,
    ...inputProps
  } = props;

  const timePattern = pattern || (withSeconds ? 'HH:mm:ss' : 'HH:mm');

  const [term, setTerm] = useState(initialValue);

  const getTermMoment = (str: string) => {
    const [hours = 0, min = 0, sec = 0] = str.split(':').map(Number);
    return moment().hours(hours).minutes(min).seconds(sec);
  };

  const handleBlurInput: React.FocusEventHandler<HTMLInputElement> = (e) => {
    let next = e.target.value;
    const [hours] = next.split(':').map(Number);
    if (hours) {
      next = getTermMoment(next).format(timePattern);
    }
    setTerm(next);
    onChange?.(next);
  };

  const handleChangeController = (value: moment.Moment | string) => {
    const next = moment(value).format(timePattern);
    setTerm(next);
    onChange?.(next);
  };

  return (
    <InputWrapper
      errorMessage={errorMessage}
      warningMessage={warningMessage}
      required={required}
      label={label}
      className={className}
    >
      <Datetime
        className={b('picker')}
        dateFormat={false}
        timeFormat={timePattern}
        value={getTermMoment(term || '')}
        onClose={() => onBlur?.()}
        onChange={handleChangeController}
        timeConstraints={timeConstraints}
        inputProps={{className: b('field'), autoComplete: 'off', ...inputProps}}
        renderInput={(_p, openCalendar) => (
          <IMaskInput
            {...inputProps}
            ref={ref}
            className={b('field')}
            overwrite="shift"
            onFocus={() => openCalendar()}
            onAccept={setTerm}
            onBlur={handleBlurInput}
            autofix="pad"
            value={term}
            mask={timePattern}
            blocks={{
              HH: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 23,
              },
              mm: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 59,
              },
              ss: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 59,
              },
            }}
            lazy
          />
        )}
      />
    </InputWrapper>
  );
});

export default TimeInput;
