import {RSAA} from 'redux-api-middleware';

import {callApi} from 'client/services/call-api';
import {getUserId, setRefreshToken} from 'client/services/cookie-data-source';
import {destroyProductFruits} from 'client/services/product-fruits/helpers';
import * as userViewModeService from 'client/services/userViewModeService';

import {API_PATH, API_METHODS} from 'client/common/config';

import * as types from './types';
import {FIND_USER_ERROR, FIND_USER_REQUEST, FIND_USER_SUCCESS} from './types';

import {setToken, setEmail, setUserId, removeAll, EXPIRATION_TIME} from '../../services/cookie-data-source';

export const findUser = (queryParams) => (dispatch) =>
  callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.USERS}/find`,
    method: 'GET',
    types: [FIND_USER_REQUEST, FIND_USER_SUCCESS, FIND_USER_ERROR],
    queryParams,
  });

export function register(signUpCred) {
  return (dispatch) => {
    function success() {
      return {
        type: types.USER_SIGNED_UP,
        payload: () => {
          return new Promise((resolve) => {
            const response = {};
            response.error = false;
            resolve(response);
          });
        },
      };
    }

    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SIGN_UP}`,
      method: 'PUT',
      types: [types.USER_SIGNING_UP, success(), types.USER_SIGN_UP_FAILED],
      body: signUpCred,
    });
  };
}
export function updatePassword(creds) {
  return (dispatch) => {
    function success() {
      return {
        type: types.USER_UPDATED_PASSWORD,
        payload: () => {
          return new Promise((resolve) => {
            const response = {};
            response.error = false;
            resolve(response);
          });
        },
      };
    }

    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PASSWORD_CHANGE}`,
      method: 'PUT',
      types: [types.USER_UPDATE_PASSWORD, success(), types.USER_UPDATED_PASSWORD_FAIL],
      body: creds,
    });
  };
}
export function recover(credentials) {
  return (dispatch) => {
    function success() {
      return {
        type: types.PASSWORD_RECOVERED,
        payload: (action, state, res) => {
          return new Promise((resolve) => {
            return res.json().then((response) => {
              response.error = false;
              resolve(response);
            });
          });
        },
      };
    }

    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PASSWORD_RECOVERY}`, // eslint-disable-line  no-process-env
      method: 'POST',
      types: [types.PASSWORD_RECOVERING, success(), types.PASSWORD_RECOVERING_FAILED],
      body: credentials,
    });
  };
}

export function authenticate(credentials, type) {
  return (dispatch) => {
    function success() {
      return {
        type: types.USER_LOGIN_SUSCCES,
        payload: (action, state, res) => {
          return new Promise((resolve) => {
            return res.json().then((response) => {
              response.error = false;
              response.rememberMe = credentials?.user?.rememberMe;
              login(response)(dispatch);
              resolve(response);
            });
          });
        },
      };
    }

    function request() {
      return {
        type: types.USER_LOGIN_REQUEST,
        meta: (action, state, res) => {
          if (res) {
            return {
              status: res.status,
              statusText: res.statusText,
            };
          }
          return {
            status: 0,
          };
        },
      };
    }

    const method = type === 'winning' ? API_METHODS.TRACKING_USER_LOGIN : API_METHODS.LOGIN;

    return callApi(dispatch, {
      endpoint: `${API_PATH}${method}`,
      method: 'POST',
      types: [request(), success(), types.USER_LOGIN_ERROR],
      body: credentials,
    });
  };
}

export const setTrackingUser = () => {
  return (dispatch) =>
    dispatch({
      type: types.USER_LOADED,
      payload: {
        user: {
          id: getUserId(),
          locale: localStorage.getItem('language'),
          type: 'PrizeTrackingUser',
        },
      },
    });
};

export function sendOtpCode(email, option) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SEND_OTP_CODE}`,
      method: 'POST',
      types: [types.SEND_OTP_REQUEST, types.SEND_OTP_SUCCESS, types.SEND_OTP_FAILED],
      body: {
        user: {
          email: email,
          send_by: option,
        },
      },
    });
  };
}

export const checkAuthToken = (body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CHECK_AUTH_TOKEN}`,
      method: 'POST',
      types: [types.CHECK_AUTH_TOKEN_REQUEST, types.CHECK_AUTH_TOKEN_SUCCESS, types.CHECK_AUTH_TOKEN_ERROR],
      body,
    });
};

export function setLoginData(user, rememberMe) {
  const {authentication_token, email, id, access_token, refresh_token} = user;
  const params = rememberMe ? {expires: EXPIRATION_TIME} : {};
  setToken(authentication_token || `Bearer ${access_token}`, params);
  refresh_token && setRefreshToken(`Bearer ${refresh_token}`);
  email && setEmail(email, params);
  setUserId(id, params);
}

export function getUserById(id) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.USERS}/${id}`,
        method: 'GET',
        isAuth: true,
        types: [types.USER_LOADING, types.USER_LOADED, types.USER_LOAD_FAILED],
      },
    });
  };
}

export function login(data) {
  return (dispatch) => {
    const user = data.user || data.prize_tracking_user;
    setLoginData(user, data.rememberMe);

    return dispatch({
      type: types.USER_LOADED,
      payload: {
        user,
      },
    });
  };
}

export function setUserEmail({email}) {
  return (dispatch) => {
    return dispatch({
      type: 'SET_EMAIL',
      payload: {
        user: {
          email,
        },
      },
    });
  };
}

export function setViewModeUserId(userId) {
  return (dispatch) => {
    userViewModeService.setViewModeStorageParam(userId);
    return dispatch({
      type: types.SET_VIEW_MODE_USER_ID,
      payload: {
        userId,
      },
    });
  };
}

export function setViewModeSettings(settings) {
  return (dispatch) => {
    userViewModeService.setViewModeSettings(settings);
    return dispatch({
      type: types.SET_VIEW_MODE_SETTINGS,
      payload: {settings},
    });
  };
}

export function logout(type) {
  const method = type === 'winning' ? API_METHODS.TRACKING_USER_LOGOUT : API_METHODS.LOGOUT;
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${method}`,
        method: 'DELETE',
        isAuth: true,
        types: [types.USER_LOGOUT_LOADING, types.USER_LOGOUT_SUCCESS, types.USER_LOGOUT_FAILED],
      },
    }).then(() => {
      removeAll();
      userViewModeService.clearViewModeStorageParam();
      userViewModeService.clearViewModeSettings();
      localStorage.removeItem('language');
      destroyProductFruits();

      dispatch({
        type: types.USER_NOT_LOADED,
        payload: {
          user: {},
        },
      });
    });
  };
}

export function denyAccess() {
  return (dispatch) => {
    removeAll();

    return dispatch({
      type: types.USER_ACCESS_DENIED,
    });
  };
}

export function expirePassword() {
  return (dispatch) => {
    removeAll();

    return dispatch({
      type: types.USER_PASSWORD_EXPIRED,
    });
  };
}
