import React from 'react';

import {CaptionLabelProps} from 'react-day-picker';

import bem from 'client/services/bem';

import cssModule from './caption-label.module.scss';

const b = bem('caption-label', {cssModule});

const CaptionLabel = (props: CaptionLabelProps) => {
  if (typeof props.children === 'string') {
    const [month, year] = props.children.split(' ');
    return (
      <div className={b()}>
        <span>{month},</span>
        <span>{year}</span>
      </div>
    );
  }

  return <span>{props.children}</span>;
};

export default CaptionLabel;
