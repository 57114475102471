import React, {useContext, useEffect} from 'react';

import {useSelector} from 'react-redux';
import useWebSocket, {ReadyState} from 'react-use-websocket';
import {WebSocketHook} from 'react-use-websocket/dist/lib/types';

import {getEmail, getToken} from 'client/services/cookie-data-source';
import {useLanguage} from 'client/services/hooks';

import {interpolate} from 'client/ducks/language/helpers';
import {selectViewMode} from 'client/ducks/user/selectors';

import {API_URL} from 'client/common/config';
import {useToast} from 'client/common/hooks/useToast';

import {WS_CHANNELS} from './constants';
import {WsMessage, WsStatisticExportMessage, WsDataTabExportMessage, WsCouponTokenMessage} from './types';

type WsProviderProps = {
  children?: React.ReactNode | React.ReactNode[];
  connect: boolean;
};

const WsContext = React.createContext<WebSocketHook>({
  sendMessage: () => {},
  sendJsonMessage: () => {},
  lastMessage: null,
  lastJsonMessage: null,
  readyState: -1,
  getWebSocket: () => null,
});
const WS_URL = `${API_URL}/cable`.replace('http', 'ws');

/**
 * WsProvider - WebSocket Provider for starting connection with WS server and defining global subscriptions (ex. for toast notifications)
 * If you need to make a special subscription on a page you can use `useWs` hook (without `connect` flag) there
 */
const WsProvider: React.FC<WsProviderProps> = ({children, connect}) => {
  const viewMode = useSelector(selectViewMode);
  let url = WS_URL + `?user_token=${getToken() || ''}&user_email=${getEmail() || ''}`;

  if (viewMode?.on && viewMode?.id) {
    url += `&fake_client_user_id=${viewMode.id}`;
  }

  const wsState = useWebSocket(url, {}, connect);
  const {sendJsonMessage, readyState, lastJsonMessage} = wsState;

  const exportLangs = useLanguage('GENERATING_EXPORT_FILE');
  const wsLang = useLanguage('WS_NOTIFICATIONS');

  const {appendToastNotification} = useToast();

  // subscribing
  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      Object.values(WS_CHANNELS).forEach((channel) => {
        sendJsonMessage({
          command: 'subscribe',
          identifier: channel,
        });
      });

      return () => {
        Object.values(WS_CHANNELS).forEach((channel) => {
          sendJsonMessage({
            command: 'unsubscribe',
            identifier: channel,
          });
        });
      };
    }
  }, [readyState, sendJsonMessage]);

  // handling events
  // some events need to be handled global in the whole app
  useEffect(() => {
    if (lastJsonMessage) {
      if ('message' in lastJsonMessage) {
        if ([WS_CHANNELS.STATISTIC_EXPORTS, WS_CHANNELS.DATA_TABS_EXPORTS].includes(lastJsonMessage.identifier)) {
          const {message} = lastJsonMessage as WsMessage<WsStatisticExportMessage | WsDataTabExportMessage>;

          appendToastNotification({
            type: message.success ? 'success' : 'error',
            title: interpolate(exportLangs.NOTIFICATION_TITLE?.toString(), {fileName: message.record.filename}),
            description: message.success ? exportLangs.NOTIFICATION_SUCCESS : exportLangs.NOTIFICATION_ERROR,
          });
        } else if (lastJsonMessage.identifier === WS_CHANNELS.COUPON_TOKENS) {
          const {message} = lastJsonMessage as WsMessage<WsCouponTokenMessage>;

          if (message.tokens_number || !message.success) {
            appendToastNotification({
              type: message.success ? 'success' : 'error',
              description: interpolate(wsLang.COUPON_TOKEN_IMPORT_FINISHED?.toString(), {
                number: message.tokens_number || 0,
              }),
            });
          }
        }
      }
    }
  }, [appendToastNotification, lastJsonMessage, exportLangs, wsLang]);

  return <WsContext.Provider value={wsState}>{children}</WsContext.Provider>;
};

export const useWs = () => useContext(WsContext);

export default WsProvider;
