import {useEffect, useMemo, useState} from 'react';

import moment from 'moment/moment';
import {DateRange, OnSelectHandler} from 'react-day-picker';

import {parseDate} from 'client/services/utils/date';
import {parseDateToObject} from 'client/services/utils/date/parseDate';

import {getStringValue, isDateRange} from './helpers';
import {CalendarValue, CalendarValueRange, CalendarValueSingle, DatepickerCalendarProps} from './types';

/**
 * Helper for defining values as handling selecting
 */
export const useSelection = <
  TMode extends 'single' | 'range',
  TValue extends CalendarValue = TMode extends 'single' ? CalendarValueSingle : CalendarValueRange,
  TSelected = TMode extends 'single' ? Date : DateRange,
>(
  mode: TMode,
  props: DatepickerCalendarProps<TMode, TValue>,
) => {
  const {value, onChange, timezone, outputFormat = 'DATE', onChangeMonth, selectedMonth} = props;

  const defaultMonth = useMemo(() => {
    const valueString = selectedMonth || (isDateRange(value) ? value.from : (value as CalendarValueSingle));
    return parseDateToObject(valueString, {timezone}) || new Date();
  }, [selectedMonth, timezone, value]);

  const [month, setMonth] = useState(defaultMonth);

  useEffect(() => {
    setMonth(defaultMonth);
  }, [defaultMonth]);

  const handleSelect: OnSelectHandler<Date | DateRange> = (rangeOrSingle) => {
    if (mode === 'range') {
      const range = (rangeOrSingle || {}) as DateRange;
      onChange?.({
        from: parseDate(range.from, {timezone, outputFormat}),
        to: parseDate(range.to, {timezone, outputFormat}),
      } as TValue);
      return;
    }

    onChange?.(parseDate(rangeOrSingle as Date, {outputFormat, timezone}) as TValue);
  };

  const selected: TSelected | undefined = useMemo(() => {
    if (isDateRange(value)) {
      return {
        from: value.from && moment(parseDate(value.from, {timezone})).toDate(),
        to: value.to && moment(parseDate(value.to, {timezone})).toDate(),
      } as TSelected;
    } else if (value) {
      const m = moment(parseDate(getStringValue(value), {timezone}));
      if (m.isValid()) {
        return m.toDate() as TSelected;
      }
    }
  }, [value, timezone]);

  const handleChangeMonth = (date: Date) => {
    setMonth(date);
    onChangeMonth?.(parseDate(date)!);
  };

  return {
    selected,
    select: handleSelect,

    defaultMonth: defaultMonth,
    month: month || new Date(),
    setMonth: handleChangeMonth,
  };
};
