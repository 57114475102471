import React, {HTMLAttributes} from 'react';

import cn from 'classnames';
import SVGInline from 'react-svg-inline';

import bem from 'client/services/bem';

import ICONS_COLORED from './iconsColoredConfig.json';
import ICONS_MONOCHROME from './iconsMonochromeConfig.json';

import './icons.scss';

const icons = {...ICONS_MONOCHROME, ICONS_COLORED};

export type IconName = keyof typeof icons;

type IconProps = {
  // TODO: need to remove using icons' names that don't exist in the project
  name: IconName | string;
  title?: string;
  className?: string;
  width?: number | string;
  height?: number | string;
  testId?: string;
  budget?: boolean;
} & HTMLAttributes<SVGSVGElement>;

const b = bem('icon-new');

const Icon: React.FC<IconProps> = ({name, className, testId = 'icon', budget, ...rest}) => {
  const icon =
    ((ICONS_COLORED as Record<string, string>)[name] as string) || (ICONS_MONOCHROME as Record<string, string>)[name];
  const clickableClass = rest.onClick ? 'icon-new--clickable' : '';
  let res;

  if (!icon) {
    res = null;
  } else {
    const viewBox = icon
      .match(/viewBox="(\d+ \d+ \d+ \d+)"/)?.[0]
      ?.match(/\d+ \d+ \d+ \d+/)?.[0]
      ?.split(' ');
    const viewBoxWidth = viewBox?.[2];
    const viewBoxHeight = viewBox?.[3];

    res = (
      <SVGInline
        className={cn(b({budget}), `icon-new-${name}`, className, clickableClass)}
        svg={icon}
        {...rest}
        width={`${rest.width?.toString() || viewBoxWidth}px`}
        height={`${rest.height?.toString() || viewBoxHeight}px`}
        data-testid={testId}
      />
    );
  }
  return res;
};

export default Icon;
