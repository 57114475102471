import React from 'react';

import bem from 'client/services/bem';
import {useLanguage, useViewMode} from 'client/services/hooks';

import ToggleInput from 'client/common/inputs/toggle-input';
import Dropdown from 'client/common/selects/dropdown';

import cssModule from './view-mode-settings-dropdown.module.scss';

const b = bem('view-mode-settings-dropdown', {cssModule});

const ViewModeSettingsDropdown: React.FC = () => {
  const lang = useLanguage('HEADER.VIEW_MODE_SETTINGS_DROPDOWN');
  const {viewMode, toggleViewModeSetting} = useViewMode();

  if (!viewMode.on) {
    return null;
  }

  return (
    <Dropdown
      position="bottom-end"
      iconName="gear-outline"
      triggerProps={{
        iconConfig: {width: 16, height: 16},
      }}
      options={[
        {
          label: (
            <ToggleInput
              className={b('toggle')}
              label={lang.EDITOR_V2}
              checked={viewMode.settings.operationPathFlow}
              labelPosition="right"
              rounded
            />
          ),
          onClick: () => toggleViewModeSetting('operationPathFlow'),
        },
      ]}
    />
  );
};

export default ViewModeSettingsDropdown;
