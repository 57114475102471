import {getHashViewModeUserId, getViewModeStorageParam, getViewModeSettings} from 'client/services/userViewModeService';

import PrivacyPolicyTypes from 'client/ducks/privacy-policies/types';

import {mapRole} from './helper';
import * as types from './types';

const vieModeId = getHashViewModeUserId();

const init = {
  type: types.USER_NOT_LOADED,
  payload: {
    user: {},
    role: mapRole(),
  },
  viewMode: {
    on: getViewModeStorageParam().on || !!vieModeId,
    id: getViewModeStorageParam().id || vieModeId || 0,
    settings: {
      operationPathFlow: false,
      ...getViewModeSettings(),
    },
  },
  isAccessDenied: false,
  isPasswordExpired: false,
};

export default function (_state_ = init, {type, payload}) {
  let state = Object.assign({}, _state_);

  switch (type) {
    case types.USER_LOADED:
      return Object.assign(
        {type, payload},
        {
          ...state,
          type: types.USER_LOADED,
          payload: {
            user: payload.user,
            role: mapRole(payload.user),
          },
          isAccessDenied: false,
          isPasswordExpired: false,
        },
      );

    case types.USER_NOT_LOADED:
      return Object.assign(
        {type, payload},
        {
          ...state,
          type: types.USER_NOT_LOADED,
          payload,
          isAccessDenied: false,
        },
      );

    case types.USER_ACCESS_DENIED:
      return {
        ...state,
        type: types.USER_ACCESS_DENIED,
        isAccessDenied: true,
      };

    case types.USER_PASSWORD_EXPIRED:
      return {
        ...state,
        payload: {
          user: {},
          role: mapRole(),
        },
        type: types.USER_PASSWORD_EXPIRED,
        isPasswordExpired: true,
      };

    case types.SET_VIEW_MODE_USER_ID:
      return {
        ...state,
        viewMode: {
          ...state.viewMode,
          on: true,
          id: payload.userId,
        },
      };

    case types.SET_VIEW_MODE_SETTINGS:
      return {
        ...state,
        viewMode: {
          ...state.viewMode,
          settings: {
            ...state.viewMode.settings,
            ...payload.settings,
          },
        },
      };

    case types.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        viewMode: {
          on: false,
          id: 0,
          settings: {
            operationPathFlow: false,
          },
        },
      };

    case PrivacyPolicyTypes.POST_ACCEPT_PRIVACY_POLICY_SUCCESS:
      state.payload.user = payload.user;
      return state;

    default:
      return state;
  }
}
