import {CalendarValueRange} from 'client/common/inputs/datetime/datepicker-calendar/types';

export const isDateRange = (value: unknown): value is CalendarValueRange => {
  return !!value && typeof value === 'object';
};

export const getStringValue = (value: unknown) => {
  if (isDateRange(value)) {
    return value.from;
  } else if (typeof value === 'string') {
    return value;
  }
  return '';
};
