import React, {useEffect, useMemo, useState} from 'react';

import placeholder from 'assets/icons/colored/pic-placeholder.svg';
import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import FileButton from 'client/common/buttons/file-button';
import {MEDIA_MIME_TYPES} from 'client/common/config';
import MediaContainer from 'client/common/containers/media-container';
import {ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import Spinner from 'client/components/common/spinner';

import {MediaInputProps} from './type';

import cssModule from './media-input.module.scss';

const b = bem('media-input', {cssModule});

const MediaInput: React.FC<MediaInputProps> = (props) => {
  const {
    className,
    classNames,
    buttonClassName,
    label,
    filename,
    name,
    onChange,
    errorMessage,
    warningMessage = '',
    width = 0,
    height = 0,
    acceptFormats,
    onBlur,
    disabled,
    value,
    mediaType,
    keepRatio,
    imageStyle,
    required = false,
    placeholderIcon = placeholder,
    requirementsComment = '',
  } = props;

  const lang = useLanguage('COMMON');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (value && mediaType && mediaType !== MEDIA_MIME_TYPES.mp4) {
      setLoading(false);
      return;
    }
    if (value) {
      setLoading(true);
    }
  }, [mediaType, value]);

  const handleChange = (file: any) => {
    onChange(file);
    onBlur?.();
  };

  const mediaContainer = useMemo(
    () => (
      <MediaContainer
        className={b('image-container', {'without-value': !value})}
        sources={value ? [{src: value, type: mediaType}] : []}
        width={width}
        height={height}
        keepRatio={!!value && keepRatio}
        imageStyle={imageStyle}
        placeholderIcon={placeholderIcon as string}
        isMediaPreview={false} // If it's false, then show media HTML tags.
        onLoadStart={() => setLoading(false)}
      />
    ),
    [value, mediaType, width, height, keepRatio, imageStyle, placeholderIcon],
  );

  return (
    <div className={cn(b({error: !!errorMessage, warning: !!warningMessage}), className)}>
      {label && (
        <label htmlFor={name} className={cn(b('label'), classNames?.label)}>
          {label}
        </label>
      )}

      {loading && <Spinner centered />}

      {/* mp4, mp3, pdf */}
      {mediaType !== MEDIA_MIME_TYPES.html && value && mediaContainer}

      {!loading && (
        <div className={b('controls')}>
          <FileButton
            className={buttonClassName}
            name={name}
            disabled={disabled}
            label={
              mediaType === MEDIA_MIME_TYPES.html || !value ? (
                <div className={b('container')}>
                  {/* html and empty state */}
                  {mediaContainer}
                  <span className={b('preview-text', {'without-value': !value, 'with-value': !!value})}>
                    {lang.UPLOAD}
                  </span>
                </div>
              ) : (
                lang.UPLOAD_NEW
              )
            }
            onChange={handleChange}
            acceptFormats={acceptFormats}
            color="devices"
            link={true}
          />
          {(filename || requirementsComment) && (
            <p className={cn(b('file-info'), classNames?.fileinfo)}>
              {filename && <span className={cn(b('filename'), classNames?.filename)}>{filename}</span>}
              {requirementsComment && (
                <span className={cn(b('requirements'), classNames?.requirements)}>{requirementsComment}</span>
              )}
            </p>
          )}
        </div>
      )}

      {!errorMessage && required && <RequiredLabel />}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}
    </div>
  );
};

export default MediaInput;
