import moment from 'moment-timezone';

import {getDateFormat} from './date';
import {normalizeDate} from './normalizeDate';

export type DateOutputFormat =
  | 'DATE'
  | 'DATE_TIME'
  | 'DATE_TIME_SECOND'
  | 'ISO'
  | 'ISO_DATE'
  | 'TIME'
  | 'TIME_SECONDS'
  | 'DATE_SHORT';

type ParseDateParams = {
  timezoneForce?: boolean;
  outputFormat?: DateOutputFormat;
  timezone?: string;
  time?: 'startDay' | 'endDay' | `${number}:${number}`;
};

/**
 * parseDate - simple method for parsing date by parameters;
 * @param value - date for transform. It can be any format (DD/MM/YYYY, YYYY-MM-DD, ISO)
 * @param params - extra params for method
 * @param params.outputFormat - output date format
 * @param params.timezone - next timezone
 * @param params.timezoneForce - flag for not taking current user's timezone into account.
 * @param params.time - setting special time (only for ISO output format).
 */
export const parseDate = (value: string | Date | undefined, params?: ParseDateParams) => {
  const {timezoneForce, outputFormat = 'ISO', timezone, time} = params || {};

  if (!value) {
    return '';
  }

  try {
    const date = normalizeDate(value);
    let currentDateFormat = getDateFormat(date);
    currentDateFormat = !currentDateFormat && timezoneForce ? 'YYYY-MM-DDTHH:mm:SS' : currentDateFormat;

    let m = moment(date, currentDateFormat);

    if (!m.isValid()) {
      throw Promise.reject("Unknown date's format");
    }

    m = setTimezone(m, {timezone, force: timezoneForce});
    m = setTime(m, time);

    return formatDate(m, outputFormat);
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const parseDateToObject = (value: string | undefined, params?: Omit<ParseDateParams, 'outputFormat'>) => {
  if (!value) {
    return;
  }

  const isoDate = parseDate(value, params);
  return new Date(isoDate);
};

function setTimezone(
  m: moment.Moment,
  params: {
    timezone: ParseDateParams['timezone'];
    force: ParseDateParams['timezoneForce'];
  },
) {
  const {timezone, force} = params;

  if (!timezone) {
    return m;
  }

  if (force) {
    // Заменяем только таймзону без пересчета времени
    const isoString = m.format('YYYY-MM-DDTHH:mm:ss.SSS'); // Получаем UTC время без таймзоны
    return moment.tz(isoString, timezone); // Устанавливаем таймзону
  }
  // Пересчитываем время для новой таймзоны
  return m.clone().tz(timezone);
}

function setTime(m: moment.Moment, time: ParseDateParams['time']) {
  if (time) {
    if (time === 'endDay') {
      m.hours(23).minutes(59).seconds(59);
    } else if (time === 'startDay') {
      m.hours(0).minutes(0).seconds(0);
    } else {
      const times = time.split(':');
      m.hours(+times[0])
        .minutes(+times[1])
        .seconds(+(times[2] || 0));
    }
  }
  return m;
}

function formatDate(m: moment.Moment, format: DateOutputFormat) {
  if (format === 'ISO') {
    return m.toISOString(true);
  } else if (format === 'ISO_DATE') {
    return m.format('YYYY-MM-DD');
  } else if (format === 'DATE') {
    return m.format('DD/MM/YYYY');
  } else if (format === 'DATE_SHORT') {
    return m.format('DD/MM/YY');
  } else if (format === 'DATE_TIME') {
    return m.format('DD/MM/YYYY HH:mm');
  } else if (format === 'DATE_TIME_SECOND') {
    return m.format('DD/MM/YYYY HH:mm:ss');
  } else if (format === 'TIME') {
    return m.format('HH:mm');
  } else if (format === 'TIME_SECONDS') {
    return m.format('HH:mm:ss');
  }

  return m.format(format);
}
