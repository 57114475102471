import React from 'react';

import {Route} from 'routes/types';

import {ADMIN_PAGES, APP_ROLES, CLIENT_PAGES, NAV_GROUPS_MAP, PAGES_BACKGROUND_COLORS} from 'client/common/config';

const AdminsListPage = React.lazy(() => import('client/pages/admins-list'));
const AdminProfilePage = React.lazy(() => import('client/pages/admins-profile'));
const AgencyPage = React.lazy(() => import('client/pages/agency'));
const AutotaskPage = React.lazy(() => import('client/pages/autotask'));
const AutotaskPlanPage = React.lazy(() => import('client/pages/autotask-plan'));
const AutotaskPlanPlacesPage = React.lazy(() => import('client/pages/autotask-plan-places'));
const AutotaskScenarioPage = React.lazy(() => import('client/pages/autotask-scenario'));
const CampaignPage = React.lazy(() => import('client/pages/campaign'));
const ClientsListPage = React.lazy(() => import('client/pages/clients-list'));
const CompanyPage = React.lazy(() => import('client/pages/company'));
const CustomOperationDatabasePage = React.lazy(() => import('client/pages/custom-operation-database'));
const DevicesPage = React.lazy(() => import('client/pages/devices'));
const EmailTemplateEditorAdminPage = React.lazy(() => import('client/pages/email-template-editor-admin-page'));
const InterfaceTemplatesPage = React.lazy(() => import('client/pages/interface-templates'));
const LeadsExportPage = React.lazy(() => import('client/pages/leads-export'));
const LeadsImportPage = React.lazy(() => import('client/pages/leads-import'));
const LeadsListPage = React.lazy(() => import('client/pages/leads-list'));
const LeadsProfilePage = React.lazy(() => import('client/pages/leads-profile'));
const LogsPage = React.lazy(() => import('client/pages/logs'));
const MailingListPage = React.lazy(() => import('client/pages/mailing-list'));
const MailingListTasksPage = React.lazy(() => import('client/pages/mailing-list-tasks'));
const MessageTaskPage = React.lazy(() => import('client/pages/message-task'));
const MessageTaskResultsPage = React.lazy(() => import('client/pages/message-task-results'));
const MessageTaskSettingsPage = React.lazy(() => import('client/pages/message-task-settings'));
const OperationPage = React.lazy(() => import('client/pages/operation'));
const OperationsPage = React.lazy(() => import('client/pages/operations'));
const ParametersPage = React.lazy(() => import('client/pages/parameters'));
const ParticipantsExportPage = React.lazy(() => import('client/pages/participants-export'));
const ParticipantsListPage = React.lazy(() => import('client/pages/participants-list'));
const PlacesPage = React.lazy(() => import('client/pages/places'));
const ScenarioRunTablePage = React.lazy(() => import('client/pages/scenario-run-table'));
const SourcesPage = React.lazy(() => import('client/pages/sources'));
const StoresPage = React.lazy(() => import('client/pages/stores'));
const TemplatePage = React.lazy(() => import('client/pages/template'));
const TemplatesPage = React.lazy(() => import('client/pages/templates'));
const TestParametersPage = React.lazy(() => import('client/pages/test-parameters'));
const TokensPage = React.lazy(() => import('client/pages/tokens'));
const TranslationsPage = React.lazy(() => import('client/pages/translations'));
const VisualsClientPage = React.lazy(() => import('client/pages/visuals-client'));
const VisualsGeneralPage = React.lazy(() => import('client/pages/visuals-general'));
const WinnersTablePage = React.lazy(() => import('client/pages/winners'));

export default [
  {
    component: LogsPage,
    name: 'LogsPage',
    path: CLIENT_PAGES.LOGS,
    params: {
      background: PAGES_BACKGROUND_COLORS.ADMINS,
      permissions: [APP_ROLES.SUPER_ADMIN],
    },
  } as Route<'LogsPage'>,
  {
    component: CampaignPage,
    name: 'CampaignPage',
    path: `${CLIENT_PAGES.CAMPAIGN}/:id`,
    params: {
      background: PAGES_BACKGROUND_COLORS.CAMPAIGN,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'CampaignPage'>,
  {
    component: AutotaskScenarioPage,
    name: 'AutotaskScenarioPage',
    path: CLIENT_PAGES.SCENARIO + '/:id',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.AUTOTASK_SCENARIO,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'AutotaskScenarioPage'>,
  {
    component: LeadsListPage,
    name: 'LeadPage',
    path: CLIENT_PAGES.LEADS_LIST + '/:id',
    params: {
      background: PAGES_BACKGROUND_COLORS.LEADS,
      navGroup: NAV_GROUPS_MAP.LEADS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'LeadPage'>,
  {
    component: LeadsListPage,
    name: 'LeadsListPage',
    path: CLIENT_PAGES.LEADS_LIST,
    params: {
      background: PAGES_BACKGROUND_COLORS.LEADS,
      navGroup: NAV_GROUPS_MAP.DATABASES,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'LeadsListPage'>,
  {
    component: LeadsImportPage,
    name: 'LeadsImportPage',
    path: CLIENT_PAGES.LEADS_IMPORT + '/:importId',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.LEADS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'LeadsImportPage'>,
  {
    component: LeadsExportPage,
    name: 'LeadsExportPage',
    path: CLIENT_PAGES.LEADS_EXPORT + '/:exportId',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.LEADS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'LeadsExportPage'>,
  {
    component: AutotaskPlanPage,
    name: 'AutotaskPlanPage',
    path: CLIENT_PAGES.PLANS + '/:id' + CLIENT_PAGES.DEVICES,
    params: {
      background: PAGES_BACKGROUND_COLORS.AUTOTASK_SCENARIO,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'AutotaskPlanPage'>,
  {
    component: AutotaskPlanPlacesPage,
    name: 'AutotaskPlanPlacesPage',
    path: CLIENT_PAGES.PLANS + '/:id' + CLIENT_PAGES.PLACES,
    params: {
      background: PAGES_BACKGROUND_COLORS.AUTOTASK_SCENARIO,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'AutotaskPlanPlacesPage'>,

  {
    component: VisualsGeneralPage,
    name: 'VisualsGeneralPage',
    path: CLIENT_PAGES.VISUALS,
    params: {
      navGroup: NAV_GROUPS_MAP.DATABASES,
      background: PAGES_BACKGROUND_COLORS.VISUALS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'VisualsGeneralPage'>,
  {
    component: VisualsGeneralPage,
    name: 'VisualsGeneralAnalysisPage',
    path: CLIENT_PAGES.VISUALS + CLIENT_PAGES.ANALYSIS,
    params: {
      navGroup: NAV_GROUPS_MAP.DATABASES,
      background: PAGES_BACKGROUND_COLORS.VISUALS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'VisualsGeneralAnalysisPage'>,
  {
    component: MailingListTasksPage,
    name: 'MailingListTasksAgenciesPage',
    path:
      CLIENT_PAGES.AGENCIES + '/:clientId' + CLIENT_PAGES.MAILING_LIST + '/:broadcastId' + CLIENT_PAGES.MESSAGE_TASKS,
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.BROADCASTING,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'MailingListTasksAgenciesPage'>,

  {
    component: MailingListTasksPage,
    name: 'MailingListTasksCompaniesPage',
    path:
      CLIENT_PAGES.COMPANIES + '/:clientId' + CLIENT_PAGES.MAILING_LIST + '/:broadcastId' + CLIENT_PAGES.MESSAGE_TASKS,
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.BROADCASTING,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'MailingListTasksCompaniesPage'>,
  {
    component: MessageTaskResultsPage,
    name: 'MessageTaskResultsCompaniesPage',
    path:
      CLIENT_PAGES.COMPANIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.MESSAGE_TASK +
      '/:taskId' +
      CLIENT_PAGES.MESSAGE_TASK_RESULTS,
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.BROADCASTING,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'MessageTaskResultsCompaniesPage'>,
  {
    component: MessageTaskResultsPage,
    name: 'MessageTaskResultsAgenciesPage',
    path:
      CLIENT_PAGES.AGENCIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.MESSAGE_TASK +
      '/:taskId' +
      CLIENT_PAGES.MESSAGE_TASK_RESULTS,
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.BROADCASTING,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'MessageTaskResultsAgenciesPage'>,
  {
    component: OperationPage,
    name: 'OperationAgenciesPage',
    exact: true,
    path: CLIENT_PAGES.AGENCIES + '/:clientId' + CLIENT_PAGES.OPERATIONS + '/:id',
    params: {
      background: PAGES_BACKGROUND_COLORS.OPERATIONS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'OperationAgenciesPage'>,
  {
    component: StoresPage,
    name: 'StoresCompaniesPage',
    path: CLIENT_PAGES.COMPANIES + '/:id' + CLIENT_PAGES.STORES,
    params: {
      background: PAGES_BACKGROUND_COLORS.STORES,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'StoresCompaniesPage'>,
  {
    component: VisualsClientPage,
    name: 'VisualsClientCompaniesPage',
    path: CLIENT_PAGES.COMPANIES + '/:id' + CLIENT_PAGES.VISUALS,
    params: {
      background: PAGES_BACKGROUND_COLORS.VISUALS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'VisualsClientCompaniesPage'>,

  {
    component: TemplatePage,
    name: 'TemplateCompaniesPage',
    path:
      CLIENT_PAGES.COMPANIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.TEMPLATES +
      '/:id',
    params: {
      background: PAGES_BACKGROUND_COLORS.TEMPLATES,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'TemplateCompaniesPage'>,
  {
    component: TemplatesPage,
    name: 'TemplatesPage',
    path: CLIENT_PAGES.COMPANIES + '/:clientId' + CLIENT_PAGES.OPERATIONS + '/:operationId' + CLIENT_PAGES.TEMPLATES,
    params: {
      background: PAGES_BACKGROUND_COLORS.TEMPLATES,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'TemplatesPage'>,

  {
    component: AgencyPage,
    name: 'AgencyPage',
    path: CLIENT_PAGES.AGENCIES + '/:id',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.AGENCY,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'AgencyPage'>,
  {
    component: CompanyPage,
    name: 'CompanyPage',
    path: CLIENT_PAGES.COMPANIES + '/:id',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.AGENCY,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'CompanyPage'>,
  {
    component: ClientsListPage,
    name: 'ClientsListMyPage',
    path: CLIENT_PAGES.CLIENTS_LIST_MY,
    exact: true,
    params: {
      nobreadcrumbs: true,
      background: PAGES_BACKGROUND_COLORS.ADMINS,
      navGroup: NAV_GROUPS_MAP.CLIENTS,
      forCurrentUser: true,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'ClientsListMyPage'>,
  {
    component: ClientsListPage,
    name: 'ClientsListPage',
    path: CLIENT_PAGES.CLIENTS_LIST,
    exact: true,
    params: {
      nobreadcrumbs: true,
      background: PAGES_BACKGROUND_COLORS.ADMINS,
      navGroup: NAV_GROUPS_MAP.CLIENTS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN, APP_ROLES.USER],
    },
  } as Route<'ClientsListPage'>,
  {
    component: StoresPage,
    name: 'StoresAgenciesPage',
    path: CLIENT_PAGES.AGENCIES + '/:id' + CLIENT_PAGES.STORES,
    params: {
      background: PAGES_BACKGROUND_COLORS.STORES,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'StoresAgenciesPage'>,
  {
    component: VisualsClientPage,
    name: 'VisualsClientAgenciesPage',
    path: CLIENT_PAGES.AGENCIES + '/:id' + CLIENT_PAGES.VISUALS,
    params: {
      background: PAGES_BACKGROUND_COLORS.VISUALS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'VisualsClientAgenciesPage'>,
  {
    component: TemplatePage,
    name: 'TemplateAgenciesPage',
    path:
      CLIENT_PAGES.AGENCIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.TEMPLATES +
      '/:id',
    params: {
      background: PAGES_BACKGROUND_COLORS.TEMPLATES,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'TemplateAgenciesPage'>,
  {
    component: TemplatesPage,
    name: 'TemplatesAgenciesPage',
    path: CLIENT_PAGES.AGENCIES + '/:clientId' + CLIENT_PAGES.OPERATIONS + '/:operationId' + CLIENT_PAGES.TEMPLATES,
    params: {
      background: PAGES_BACKGROUND_COLORS.TEMPLATES,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'TemplatesAgenciesPage'>,
  {
    component: ParticipantsExportPage,
    name: 'ParticipantsExportCompaniesPage',
    path:
      CLIENT_PAGES.COMPANIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.AUTOTASK +
      '/:taskId' +
      CLIENT_PAGES.PARTICIPANTS_EXPORT +
      '/:exportId',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.PARTICIPATIONS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'ParticipantsExportCompaniesPage'>,
  {
    component: ParticipantsListPage,
    name: 'ParticipantsListCompaniesPage',
    path:
      CLIENT_PAGES.COMPANIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.AUTOTASK +
      '/:taskId' +
      CLIENT_PAGES.PARTICIPANTS_LIST,
    params: {
      background: PAGES_BACKGROUND_COLORS.PARTICIPATIONS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'ParticipantsListCompaniesPage'>,
  {
    component: EmailTemplateEditorAdminPage,
    name: 'EmailTemplateEditorAdminPage',
    path: ADMIN_PAGES.EMAIL_TEMPLATE_EDITOR,
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.ADMIN_EMAIL_TEMPLATE_EDITOR,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'EmailTemplateEditorAdminPage'>,
  {
    component: AdminProfilePage,
    name: 'AdminProfilePage',
    path: CLIENT_PAGES.ADMINS_LIST + '/:id',
    params: {
      background: PAGES_BACKGROUND_COLORS.ADMINS,
      permissions: [APP_ROLES.SUPER_ADMIN],
    },
  } as Route<'AdminProfilePage'>,
  {
    component: AdminsListPage,
    name: 'AdminsListPage',
    path: CLIENT_PAGES.ADMINS_LIST,
    params: {
      nobreadcrumbs: true,
      navGroup: NAV_GROUPS_MAP.ADMINS,
      background: PAGES_BACKGROUND_COLORS.ADMINS,
      permissions: [APP_ROLES.SUPER_ADMIN],
    },
  } as Route<'AdminsListPage'>,
  {
    component: PlacesPage,
    name: 'PlacesPage',
    path: CLIENT_PAGES.PLACES,
    params: {
      nobreadcrumbs: true,
      background: PAGES_BACKGROUND_COLORS.PLACES,
      navGroup: NAV_GROUPS_MAP.REFERENCES,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'PlacesPage'>,
  {
    component: DevicesPage,
    name: 'DevicesPage',
    path: CLIENT_PAGES.DEVICES,
    params: {
      nobreadcrumbs: true,
      background: PAGES_BACKGROUND_COLORS.DEVICES,
      navGroup: NAV_GROUPS_MAP.REFERENCES,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'DevicesPage'>,
  {
    component: ScenarioRunTablePage,
    name: 'ScenarioRunTablePage',
    path: CLIENT_PAGES.SCENARIO_RUN_TABLE,
    params: {
      navGroup: NAV_GROUPS_MAP.OPERATIONS,
      background: PAGES_BACKGROUND_COLORS.OPERATIONS,
      permissions: [APP_ROLES.SUPER_ADMIN],
    },
  } as Route<'ScenarioRunTablePage'>,
  {
    component: OperationsPage,
    name: 'OperationsPage',
    path: CLIENT_PAGES.OPERATIONS,
    exact: true,
    params: {
      nobreadcrumbs: true,
      navGroup: NAV_GROUPS_MAP.OPERATIONS,
      background: PAGES_BACKGROUND_COLORS.OPERATIONS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'OperationsPage'>,
  {
    component: OperationsPage,
    name: 'OperationsPage',
    path: CLIENT_PAGES.OPERATIONS_MY,
    exact: true,
    params: {
      navGroup: NAV_GROUPS_MAP.OPERATIONS,
      background: PAGES_BACKGROUND_COLORS.OPERATIONS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'OperationsPage'>,
  {
    component: ScenarioRunTablePage,
    name: 'ScenarioRunTableAgenciesPage',
    path:
      CLIENT_PAGES.AGENCIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.AUTOTASK +
      '/:autotaskId' +
      CLIENT_PAGES.SCENARIO_RUN_TABLE,
    params: {
      background: PAGES_BACKGROUND_COLORS.OPERATIONS,
      permissions: [APP_ROLES.SUPER_ADMIN],
    },
  } as Route<'ScenarioRunTableAgenciesPage'>,
  {
    component: CustomOperationDatabasePage,
    name: 'CustomOperationDatabaseAgenciesPage',
    path:
      CLIENT_PAGES.AGENCIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.CUSTOM_OPERATION_DATABASE,
    params: {
      background: PAGES_BACKGROUND_COLORS.OPERATIONS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'CustomOperationDatabaseAgenciesPage'>,
  {
    component: TokensPage,
    name: 'TokensAgenciesPage',
    path: CLIENT_PAGES.AGENCIES + '/:clientId' + CLIENT_PAGES.OPERATIONS + '/:operationId' + CLIENT_PAGES.TOKENS,
    params: {
      background: PAGES_BACKGROUND_COLORS.TOKENS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'TokensAgenciesPage'>,
  {
    component: ParticipantsListPage,
    name: 'ParticipantsListPage',
    path: CLIENT_PAGES.PARTICIPANTS_LIST,
    params: {
      background: PAGES_BACKGROUND_COLORS.PARTICIPATIONS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'ParticipantsListPage'>,
  {
    component: ParametersPage,
    name: 'ParametersPage',
    path: CLIENT_PAGES.PARAMETERS,
    params: {
      nobreadcrumbs: true,
      background: PAGES_BACKGROUND_COLORS.PARAMETERS,
      navGroup: NAV_GROUPS_MAP.PARAMETERS,
      permissions: [APP_ROLES.SUPER_ADMIN],
    },
  } as Route<'ParametersPage'>,
  {
    component: MessageTaskPage,
    name: 'MessageTaskAgenciesPage',
    path:
      CLIENT_PAGES.AGENCIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.MESSAGE_TASK +
      '/:id',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.BROADCASTING,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'MessageTaskAgenciesPage'>,
  {
    component: MessageTaskPage,
    name: 'MessageTaskCompaniesPage',
    path:
      CLIENT_PAGES.COMPANIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.MESSAGE_TASK +
      '/:id',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.BROADCASTING,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'MessageTaskCompaniesPage'>,
  {
    component: MessageTaskSettingsPage,
    name: 'MessageTaskSettingsAgenciesPage',
    path:
      CLIENT_PAGES.AGENCIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.MESSAGE_TASK +
      '/:taskId' +
      CLIENT_PAGES.MESSAGE_TASK_SETTINGS,
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.BROADCASTING,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'MessageTaskSettingsAgenciesPage'>,
  {
    component: MessageTaskSettingsPage,
    name: 'MessageTaskSettingsCompaniesPage',
    path:
      CLIENT_PAGES.COMPANIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.MESSAGE_TASK +
      '/:taskId' +
      CLIENT_PAGES.MESSAGE_TASK_SETTINGS,
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.BROADCASTING,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'MessageTaskSettingsCompaniesPage'>,
  {
    component: MailingListPage,
    name: 'MailingListAgenciesPage',
    path:
      CLIENT_PAGES.AGENCIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.MESSAGE_TASK +
      '/:taskId' +
      CLIENT_PAGES.MAILING_LIST +
      '/:broadcastId',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.BROADCASTING,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'MailingListAgenciesPage'>,
  {
    component: MailingListPage,
    name: 'MailingListCompaniesPage',
    path:
      CLIENT_PAGES.COMPANIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.MESSAGE_TASK +
      '/:taskId' +
      CLIENT_PAGES.MAILING_LIST +
      '/:broadcastId',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.BROADCASTING,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'MailingListCompaniesPage'>,
  {
    component: ScenarioRunTablePage,
    name: 'ScenarioRunTableCompaniesPage',
    path:
      CLIENT_PAGES.COMPANIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.AUTOTASK +
      '/:autotaskId' +
      CLIENT_PAGES.SCENARIO_RUN_TABLE,
    params: {
      background: PAGES_BACKGROUND_COLORS.OPERATIONS,
      permissions: [APP_ROLES.SUPER_ADMIN],
    },
  } as Route<'ScenarioRunTableCompaniesPage'>,
  {
    component: CustomOperationDatabasePage,
    name: 'CustomOperationDatabaseCompaniesPage',
    path:
      CLIENT_PAGES.COMPANIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.CUSTOM_OPERATION_DATABASE,
    params: {
      background: PAGES_BACKGROUND_COLORS.OPERATIONS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'CustomOperationDatabaseCompaniesPage'>,
  {
    component: TokensPage,
    name: 'TokensCompaniesPage',
    path: CLIENT_PAGES.COMPANIES + '/:clientId' + CLIENT_PAGES.OPERATIONS + '/:operationId' + CLIENT_PAGES.TOKENS,
    params: {
      background: PAGES_BACKGROUND_COLORS.TOKENS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'TokensCompaniesPage'>,
  {
    component: OperationPage,
    name: 'OperationCompaniesPage',
    exact: true,
    path: CLIENT_PAGES.COMPANIES + '/:clientId' + CLIENT_PAGES.OPERATIONS + '/:id',
    params: {
      background: PAGES_BACKGROUND_COLORS.OPERATIONS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'OperationCompaniesPage'>,
  {
    component: LeadsProfilePage,
    name: 'LeadsProfilePage',
    path: CLIENT_PAGES.LEADS_PROFILE + '/:id',
    params: {
      background: PAGES_BACKGROUND_COLORS.LEADS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'LeadsProfilePage'>,
  {
    component: AutotaskPage,
    name: 'AutotaskAgenciesPage',
    path:
      CLIENT_PAGES.AGENCIES + '/:clientId' + CLIENT_PAGES.OPERATIONS + '/:operationId' + CLIENT_PAGES.AUTOTASK + '/:id',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.AUTOTASK,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'AutotaskAgenciesPage'>,
  {
    component: AutotaskPage,
    name: 'AutotaskCompaniesPage',
    path:
      CLIENT_PAGES.COMPANIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.AUTOTASK +
      '/:id',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.AUTOTASK,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'AutotaskCompaniesPage'>,
  {
    component: InterfaceTemplatesPage,
    name: 'InterfaceTemplatesPage',
    path: CLIENT_PAGES.INTERFACE_TEMPLATES,
    params: {
      nobreadcrumbs: true,
      navGroup: NAV_GROUPS_MAP.REFERENCES,
      background: PAGES_BACKGROUND_COLORS.INTERFACE_TEMPLATES,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'InterfaceTemplatesPage'>,
  {
    component: SourcesPage,
    name: 'SourcesPage',
    path: CLIENT_PAGES.SOURCES,
    params: {
      background: PAGES_BACKGROUND_COLORS.SOURCES,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'SourcesPage'>,
  {
    component: WinnersTablePage,
    name: 'WinnersTableCompaniesPage',
    path:
      CLIENT_PAGES.COMPANIES +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.AUTOTASK +
      '/:taskId' +
      CLIENT_PAGES.WINNERS,
    params: {
      background: PAGES_BACKGROUND_COLORS.AUTOTASK,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'WinnersTableCompaniesPage'>,
  {
    component: TestParametersPage,
    name: 'TestParametersPage',
    path: `${CLIENT_PAGES.SCENARIO}/:id${CLIENT_PAGES.TEST_PARAMETERS}`,
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.TEST_PARAMETERS,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'TestParametersPage'>,
  {
    component: TranslationsPage,
    name: 'TranslationsPage',
    path: '/translations',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.ADMINS,
      permissions: [APP_ROLES.SUPER_ADMIN],
    },
  } as Route<'TranslationsPage'>,
];
