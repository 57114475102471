import React, {useCallback} from 'react';

import cn from 'classnames';
import {ColorState, SketchPicker} from 'react-color';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import {ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {ColorPickerInputProps} from './type';

import cssModule from './color-picker-input.module.scss';

const b = bem('color-picker-input', {cssModule});

const getOpacity = (hexValue: string) => {
  if (hexValue.length <= 7) {
    return 255;
  }
  return parseInt(hexValue.slice(-2), 16);
};

const isColorState = (event: unknown): event is ColorState => {
  return !!event && typeof event === 'object' && 'hex' in event;
};

const isInputEvent = (event: unknown): event is React.ChangeEvent<HTMLInputElement> => {
  return !!event && typeof event === 'object' && 'target' in event;
};

const ColorPickerInput: React.FC<ColorPickerInputProps> = (props) => {
  const {
    name,
    onChange,
    className = '',
    label = '',
    value = '',
    errorMessage = '',
    warningMessage = '',
    disabled = false,
    asHex = true,
    disableManualInput = false,
    inputValue = '',
    customColorBox = null,
    hideValue = false,
    required = false,
    ...inputProps
  } = props;
  const [open, toggleOpen] = useToggle(false);

  const lang = useLanguage('COMMON.INPUTS.COLOR_PICKER_INPUT');

  const handleChange = useCallback(
    (e: unknown) => {
      if (isInputEvent(e)) {
        onChange(e.target.value);
      } else if (isColorState(e)) {
        onChange(asHex ? e.hex : e);
      }
    },
    [onChange, asHex],
  );

  const opacity = value ? Math.round((getOpacity(value) * 100) / 255) : 0;

  return (
    <div className={cn(b({error: !!errorMessage, warning: !!warningMessage}), className)}>
      {label && (
        <label htmlFor={name} className={b('label')}>
          {label}
        </label>
      )}
      <div className={b('controls')}>
        <AppButton
          asWrap={true}
          onClick={disabled ? null : toggleOpen}
          label={customColorBox || <div className={b('color-box')} style={{backgroundColor: value}} />}
        />
        {open && (
          <div className={b('popover')}>
            <div className={b('cover')} onClick={toggleOpen} />
            <SketchPicker color={value} onChange={handleChange} disableAlpha={false} presetColors={['']} />
          </div>
        )}
        {disableManualInput ? (
          <p className={b('color-code')}>
            <span className={b('code-value', {hide: hideValue})}>{inputValue || value}</span>
            {!customColorBox && opacity && <span>{`${lang.OPACITY}: ${opacity} %`}</span>}
          </p>
        ) : (
          <input
            disabled={disabled}
            {...inputProps}
            className={b('code-input', {hide: hideValue})}
            type="text"
            name={name}
            value={inputValue || value}
            onChange={handleChange}
            aria-invalid={!!errorMessage}
          />
        )}
      </div>
      {!errorMessage && required && <RequiredLabel />}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}
    </div>
  );
};

export default ColorPickerInput;
