import React from 'react';

import {Route} from 'routes/types';

import {APP_ROLES, CLIENT_PAGES, PAGES_BACKGROUND_COLORS, USER_MEMBERSHIP} from 'client/common/config';

const AccountParametersPage = React.lazy(() => import('client/pages/account-parameters-page'));
const AgencyClientPage = React.lazy(() => import('client/pages/agency-client-page'));
const AgencyClientsPage = React.lazy(() => import('client/pages/agency-clients-page'));
const AnimationAnomaliesPage = React.lazy(() => import('client/pages/animation-anomalies-page'));
const AnimationMonitoringPage = React.lazy(() => import('client/pages/animation-monitoring-page'));
const AnimationPage = React.lazy(() => import('client/pages/animation-page'));
const AnimationPhotos = React.lazy(() => import('client/pages/animation-photos'));
const CatalogModulesPage = React.lazy(() => import('client/pages/catalog-modules-page'));
const CatalogTemplatesPage = React.lazy(() => import('client/pages/catalog-templates-page'));
const ClientAutotaskPage = React.lazy(() => import('client/pages/client-autotask-page'));
const ClientDevicesPage = React.lazy(() => import('client/pages/client-devices-page'));
const ClientOperationsPage = React.lazy(() => import('client/pages/client-operations-page'));
const InstoreTaskPage = React.lazy(() => import('client/pages/instore-task-page'));
const LeadsDashboardPage = React.lazy(() => import('client/pages/leads-dashboard-page'));
const LeadsDatabasePage = React.lazy(() => import('client/pages/leads-database-page'));
const LeadsParametersPage = React.lazy(() => import('client/pages/leads-parameters-page'));
const WelcomePage = React.lazy(() => import('client/pages/welcome-page'));

export default [
  {
    component: AgencyClientsPage,
    name: 'AgencyClientsPage',
    path: CLIENT_PAGES.CLIENTS + '/:clientId' + CLIENT_PAGES.AGENCY_CLIENTS,
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER, APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
      viewMode: true,
    },
    exact: true,
  } as Route<'AgencyClientsPage'>,
  {
    component: AgencyClientPage,
    name: 'AgencyClientPage',
    path: CLIENT_PAGES.CLIENTS + '/:clientId' + CLIENT_PAGES.AGENCY_CLIENTS + '/:agencyClientId' + '/:tab?',
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER, APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
      viewMode: true,
    },
  } as Route<'AgencyClientPage'>,
  {
    component: CatalogModulesPage,
    name: 'CatalogModulesPage',
    path: CLIENT_PAGES.CLIENTS + '/:clientId' + CLIENT_PAGES.CATALOG_MODULES,
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER, APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
      viewMode: true,
    },
  } as Route<'CatalogModulesPage'>,
  {
    component: CatalogTemplatesPage,
    name: 'CatalogTemplatesPage',
    path: CLIENT_PAGES.CLIENTS + '/:clientId' + CLIENT_PAGES.CATALOG_TEMPLATES + '/:type?',
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER, APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
      viewMode: true,
    },
  } as Route<'CatalogTemplatesPage'>,
  {
    component: AnimationPhotos,
    name: 'AnimationPhotos',
    path:
      CLIENT_PAGES.CLIENTS +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.INSTORE_TASKS +
      '/:taskId' +
      CLIENT_PAGES.ANIMATIONS +
      '/:animationId' +
      CLIENT_PAGES.PHOTOS,
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER, APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
      viewMode: true,
    },
  } as Route<'AnimationPhotos'>,
  {
    component: AnimationMonitoringPage,
    name: 'AnimationMonitoringPage',
    path:
      CLIENT_PAGES.CLIENTS +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.INSTORE_TASKS +
      '/:taskId' +
      CLIENT_PAGES.ANIMATIONS +
      '/:animationId' +
      CLIENT_PAGES.MONITORING,
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER, APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
    },
  } as Route<'AnimationMonitoringPage'>,
  {
    component: AnimationAnomaliesPage,
    name: 'AnimationAnomaliesPage',
    path:
      CLIENT_PAGES.CLIENTS +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.INSTORE_TASKS +
      '/:taskId' +
      CLIENT_PAGES.ANIMATIONS +
      '/:animationId' +
      CLIENT_PAGES.ANOMALIES,
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER, APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
      viewMode: true,
    },
  } as Route<'AnimationAnomaliesPage'>,
  {
    component: AnimationPage,
    name: 'AnimationPage',
    path:
      CLIENT_PAGES.CLIENTS +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.INSTORE_TASKS +
      '/:taskId' +
      CLIENT_PAGES.ANIMATIONS +
      '/:animationId',
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER, APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
      viewMode: true,
    },
  } as Route<'AnimationPage'>,
  {
    component: InstoreTaskPage,
    name: 'InstoreTaskPage',
    path:
      CLIENT_PAGES.CLIENTS +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.INSTORE_TASKS +
      '/:taskId' +
      '/:tab?',
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER, APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
      viewMode: true,
    },
  } as Route<'InstoreTaskPage'>,
  {
    component: ClientAutotaskPage,
    name: 'ClientAutotaskPage',
    path:
      CLIENT_PAGES.CLIENTS +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.AUTOTASK +
      '/:autotaskId' +
      '/:tab' +
      '/:subtab?',
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER, APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
      viewMode: true,
    },
  } as Route<'ClientAutotaskPage'>,
  {
    component: AccountParametersPage,
    name: 'AccountParametersPage',
    path: CLIENT_PAGES.ACCOUNT_PARAMETERS + '/:tab?',
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER, APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
      viewMode: true,
    },
  } as Route<'AccountParametersPage'>,
  {
    component: ClientOperationsPage,
    name: 'ClientOperationsPage',
    path: CLIENT_PAGES.CLIENTS + '/:clientId' + CLIENT_PAGES.OPERATIONS,
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN, APP_ROLES.USER],
      viewMode: true,
    },
  } as Route<'ClientOperationsPage'>,
  {
    component: LeadsDashboardPage,
    name: 'LeadsDashboardPage',
    path: '/clients/:clientId/leads/dashboard',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN, APP_ROLES.USER],
      viewMode: true,
    },
  } as Route<'LeadsDashboardPage'>,
  {
    component: LeadsDatabasePage,
    name: 'LeadsDatabasePage',
    path: '/clients/:clientId/leads/database',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER, APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
      viewMode: true,
    },
  } as Route<'LeadsDatabasePage'>,
  {
    component: LeadsParametersPage,
    name: 'LeadsParametersPage',
    path: '/clients/:clientId/leads/parameters',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER, APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN],
      accessLevels: [USER_MEMBERSHIP.CLIENT_ADMIN],
      viewMode: true,
    },
  } as Route<'LeadsParametersPage'>,
  {
    component: ClientDevicesPage,
    name: 'ClientDevicesPage',
    path: '/clients/:clientId/devices',
    exact: true,
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER],
    },
  } as Route<'ClientDevicesPage'>,

  {
    component: WelcomePage,
    name: 'WelcomePage',
    path: CLIENT_PAGES.WELCOME,
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      permissions: [APP_ROLES.USER],
    },
  } as Route<'WelcomePage'>,
];
