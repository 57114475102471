import {DateTimeInputTypeOptionType} from './index';

export const serialize = ({from, to}: {from: string; to?: string}) => [from, to].filter(Boolean).join(' ');

export const deserialize = (value: string, isPeriodicValue: boolean, type: DateTimeInputTypeOptionType) => {
  // Period input is not available for 'date-time' type
  if (!isPeriodicValue || type === 'date-time') {
    return {from: value};
  }

  const splittedValues = value.split(' ');
  return {from: splittedValues[0] || '', to: splittedValues[1] || ''};
};
