import moment from 'moment-timezone';

import {regionFullNames} from './regionFullNames';

export const timezones = moment.tz.names();

export const getTimezoneParams = (timezone?: string) => {
  if (timezone) {
    const currentMoment = moment().tz(timezone);

    const offset = currentMoment.format('Z');
    let regionName = currentMoment.format('z');
    regionName = offset.replace(':', '').startsWith(regionName) ? '' : regionName + ' - ';

    const names = timezone.split('/');
    const name = names[names.length - 1] || names[0];

    return {offsetByMin: currentMoment.utcOffset(), formattedName: `(${offset}) ${regionName}${name}`, timezone};
  }
  return {offsetByMin: 0, formattedName: timezone};
};

export const timezoneOptions = timezones
  .map(getTimezoneParams)
  .sort((a, b) => (a.offsetByMin < b.offsetByMin ? 1 : -1))
  .map((tz) => ({label: tz.formattedName, value: tz.timezone}));

export const getCurrentTimezone = () => moment.tz.guess();

export const formatOperationTimezone = (timezone: string) => {
  if (!timezone) {
    return {
      label: '',
      fullLabel: '',
    };
  }

  const timezoneMoment = moment().tz(timezone);
  const gmtOffset = timezoneMoment.format('Z');
  const regionName = timezoneMoment.format('z').replace(/[+-]?\d+$/, '');
  const timezoneParts = timezone.split('/');
  const locationName =
    timezoneParts[timezoneParts.length - 1]?.replace(/_/g, ' ') || timezoneParts[0]?.replace(/_/g, ' ');

  return {
    label: `(GMT${gmtOffset}) ${regionName} - ${locationName}`,
    fullLabel: `(GMT${gmtOffset}) ${regionFullNames[regionName] || regionName} - ${locationName}`,
  };
};
