import React, {useMemo} from 'react';

import cn from 'classnames';
import {DayPicker, getDefaultClassNames, isMatch} from 'react-day-picker';
import * as locales from 'react-day-picker/locale';
import type {Matcher} from 'react-day-picker/src/types/shared';

import bem from 'client/services/bem';
import {useTranslations} from 'client/services/hooks';

import * as components from './components';
import {useSelection} from './hooks';
import {CalendarValue, CalendarValueRange, CalendarValueSingle, DatepickerCalendarProps} from './types';

import 'react-day-picker/dist/style.css';

import cssModule from './styles/datepicker-calendar.module.scss';

const b = bem('datepicker-calendar', {cssModule});

const defaultClasses = getDefaultClassNames();

const LOCALE_MAPPING = {
  en: locales.enUS,
  fr: locales.fr,
} as const;

type LocalModifiers = {modifiers: Record<string, Matcher | Matcher[] | undefined>; classNames: Record<string, string>};

function DatepickerCalendar<
  TMode extends 'single' | 'range',
  TValue extends CalendarValue = TMode extends 'single' ? CalendarValueSingle : CalendarValueRange,
>(props: DatepickerCalendarProps<TMode, TValue>) {
  const {mode = 'single', required = false, modifiers: externModifiers, highlightAvailable, disabled} = props;

  const {selected, select, month, setMonth, defaultMonth} = useSelection(mode, props);

  const {currentLanguage} = useTranslations();

  const locale =
    LOCALE_MAPPING[currentLanguage as keyof typeof LOCALE_MAPPING] || locales[currentLanguage as keyof typeof locales];

  const modifiers: LocalModifiers = useMemo(() => {
    const mods: LocalModifiers['modifiers'] = {};
    const classNames: LocalModifiers['classNames'] = {};

    Object.entries(externModifiers || {}).forEach(([key, item]) => {
      mods[key] = item.matcher;
      classNames[key] = item.className;
    });

    return {modifiers: mods, classNames};
  }, [externModifiers]);

  return (
    // DayPickerProps has difficult structure of props;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <DayPicker
      disabled={disabled}
      modifiersClassNames={{
        today: b('day', ['today']),
        selected: b('day', ['selected']),
        range_start: b('day', ['range-start']),
        range_end: b('day', ['range-end']),
        range_middle: b('day', ['range-middle']),
        disabled: b('day', ['disabled']),
        available: b('day', {['highlight-available']: highlightAvailable}),
        ...modifiers.classNames,
      }}
      modifiers={{
        ...modifiers.modifiers,
        available: (date) => !disabled || !isMatch(date, disabled),
      }}
      modifiersStyles={{}}
      components={components}
      classNames={{
        ...defaultClasses,
        root: cn(defaultClasses.root, b()),
        day: cn(defaultClasses.day, b('day')),
        day_button: cn(defaultClasses.day_button, b('day-button')),
        weekday: cn(defaultClasses.weekday, b('weekday')),
        month_grid: cn(b('month-grid')),
        month_caption: cn(defaultClasses.month_caption, b('month-caption')),
        outside: cn(defaultClasses.outside, b('outside')),
        nav: cn(defaultClasses.nav, b('nav')),
        button_previous: cn(defaultClasses.button_previous, b('button-previous')),
        button_next: cn(defaultClasses.button_next, b('button-next')),
      }}
      mode={mode}
      required={required}
      selected={selected}
      showOutsideDays
      weekStartsOn={1}
      locale={locale}
      defaultMonth={defaultMonth}
      onSelect={select}
      onMonthChange={setMonth}
      month={month}
    />
  );
}

export default DatepickerCalendar;
