export default {
  GET_TRANSLATIONS: '@@translations/GET_TRANSLATIONS',
  GET_TRANSLATIONS_REQUEST: '@@templates/GET_TRANSLATIONS_REQUEST',
  GET_TRANSLATIONS_SUCCESS: '@@templates/GET_TRANSLATIONS_SUCCESS',
  GET_TRANSLATIONS_ERROR: '@@templates/GET_TRANSLATIONS_ERROR',

  POST_TRANSLATION_REQUEST: '@@templates/POST_TRANSLATION_REQUEST',
  POST_TRANSLATION_SUCCESS: '@@templates/POST_TRANSLATION_SUCCESS',
  POST_TRANSLATION_ERROR: '@@templates/POST_TRANSLATION_ERROR',

  PATCH_TRANSLATION_REQUEST: '@@templates/PATCH_TRANSLATION_REQUEST',
  PATCH_TRANSLATION_SUCCESS: '@@templates/PATCH_TRANSLATION_SUCCESS',
  PATCH_TRANSLATION_ERROR: '@@templates/PATCH_TRANSLATION_ERROR',

  DELETE_TRANSLATION_REQUEST: '@@templates/DELETE_TRANSLATION_REQUEST',
  DELETE_TRANSLATION_SUCCESS: '@@templates/DELETE_TRANSLATION_SUCCESS',
  DELETE_TRANSLATION_ERROR: '@@templates/DELETE_TRANSLATION_ERROR',
  DELETE_TRANSLATION: '@@templates/DELETE_TRANSLATION',

  PUT_TRANSLATION_REQUEST: '@@templates/PUT_TRANSLATION_REQUEST',
  PUT_TRANSLATION_SUCCESS: '@@templates/PUT_TRANSLATION_SUCCESS',
  PUT_TRANSLATION_ERROR: '@@templates/PUT_TRANSLATION_ERROR',

  POST_TRANSLATION_ITEM_REQUEST: '@@templates/POST_TRANSLATION_ITEM_REQUEST',
  POST_TRANSLATION_ITEM_SUCCESS: '@@templates/POST_TRANSLATION_ITEM_SUCCESS',
  POST_TRANSLATION_ITEM_ERROR: '@@templates/POST_TRANSLATION_ITEM_ERROR',

  PATCH_TRANSLATION_ITEM_REQUEST: '@@templates/PATCH_TRANSLATION_ITEM_REQUEST',
  PATCH_TRANSLATION_ITEM_SUCCESS: '@@templates/PATCH_TRANSLATION_ITEM_SUCCESS',
  PATCH_TRANSLATION_ITEM_ERROR: '@@templates/PATCH_TRANSLATION_ITEM_ERROR',

  PATCH_TRANSLATION_ITEMS_REQUEST: '@@templates/PATCH_TRANSLATION_ITEMS_REQUEST',
  PATCH_TRANSLATION_ITEMS_SUCCESS: '@@templates/PATCH_TRANSLATION_ITEMS_SUCCESS',
  PATCH_TRANSLATION_ITEMS_ERROR: '@@templates/PATCH_TRANSLATION_ITEMS_ERROR',

  GET_LANGUAGES_REQUEST: '@@templates/GET_LANGUAGES_REQUEST',
  GET_LANGUAGES_SUCCESS: '@@templates/GET_LANGUAGES_SUCCESS',
  GET_LANGUAGES_ERROR: '@@templates/GET_LANGUAGES_ERROR',

  PATCH_LANGUAGE_REQUEST: '@@templates/PATCH_LANGUAGE_REQUEST',
  PATCH_LANGUAGE_SUCCESS: '@@templates/PATCH_LANGUAGE_SUCCESS',
  PATCH_LANGUAGE_ERROR: '@@templates/PATCH_LANGUAGE_ERROR',
};
