import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {TranslationJsx} from 'client/models/language/types';

import {getWidth} from './helpers';

import cssModule from './textarea-input.module.scss';

const b = bem('textarea-input', {cssModule});

const TextareaInput = (props) => {
  const {
    className = '',
    classNames = {},
    textareaClassName = '',
    valueClassName = '',
    label = '',
    name = '',
    disabled = false,
    placeholder = '',
    onChange = null,
    errorMessage = null,
    warningMessage = '',
    readOnly = false,
    width = 0,
    height = 0,
    isLinesLimited = false,
    inputRef,
    required = false,
    ...textareaProps
  } = props;

  const textareaClassNames = cn(b({error: !!errorMessage, warning: !!warningMessage}), className);
  const widthStyle = getWidth(width);
  const heightStyle = height && {
    minHeight: height && `${height}px`,
    maxHeight: height && `${height}px`,
  };

  const styles = {
    ...widthStyle,
    ...heightStyle,
  };

  const handleKeyDown = (event) => {
    const textarea = event.target;
    const rows = textarea.rows || 1;
    const lines = textarea.value.split(/\r\n|\r|\n/).length;
    textarea.removeAttribute('maxLength');

    if (lines === rows && event.key === 'Enter' && isLinesLimited) {
      textarea.setAttribute('maxLength', textarea.value.length);
      textarea.style.whiteSpace = 'pre';
    }
  };

  const handleMessageChange = (event) => {
    onChange?.(event);
  };

  return (
    <div className={textareaClassNames}>
      <div className={cn(b('control'), classNames?.control)}>
        {label && (
          <label htmlFor={name} className={cn(b('label'), classNames?.label)}>
            {label}
          </label>
        )}
        {readOnly ? (
          <p className={cn(b('value'), valueClassName)}>{textareaProps.value}</p>
        ) : (
          <textarea
            id={name}
            ref={inputRef}
            name={name}
            className={cn(b('component'), textareaClassName, classNames?.input)}
            disabled={disabled}
            placeholder={placeholder}
            onChange={handleMessageChange}
            onKeyDown={handleKeyDown}
            style={styles}
            aria-invalid={!!errorMessage}
            {...textareaProps}
          />
        )}
      </div>
      {!errorMessage && required && <RequiredLabel />}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}
    </div>
  );
};

TextareaInput.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.string,
    control: PropTypes.string,
  }),
  textareaClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  label: TranslationJsx,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: TranslationJsx,
  onChange: PropTypes.func,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  warningMessage: TranslationJsx,
  readOnly: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  isLinesLimited: PropTypes.bool,
  inputRef: PropTypes.object,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  required: PropTypes.func,
};

export default TextareaInput;
