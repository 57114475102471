import React from 'react';

import cn from 'classnames';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';

import cssModule from './error-message.module.scss';

const b = bem('error-message', {cssModule});

const ErrorMessage = (props) => {
  const {className = '', errorMessage = null} = props;

  return (
    <div className={cn(b(), className)}>
      {isArray(errorMessage) && errorMessage.length !== 0 ? (
        <ul className={b('list')}>
          {errorMessage.map((error, idx) => (
            <li key={idx} className={b('message')}>
              {error}
            </li>
          ))}
        </ul>
      ) : (
        <p className={b('message')}>{errorMessage}</p>
      )}
    </div>
  );
};

ErrorMessage.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
};

export default ErrorMessage;
