import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Modal from 'client/common/modals/modal';

import cssModule from './message-modal.module.scss';

const b = bem('message-modal', {cssModule});

const MessageModal = (props) => {
  const {show = false, onClose, message = '', className = ''} = props;

  return (
    <Modal
      onClose={onClose}
      show={show}
      className={cn(b(), className)}
      classNames={{
        body: b('body'),
      }}
    >
      <button className={b('message')} type="button" onClick={onClose}>
        {message && <p className="main-text">{message}</p>}
      </button>
    </Modal>
  );
};

MessageModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.node,
  className: PropTypes.string,
};

export default MessageModal;
