export const regionFullNames: {[key: string]: string} = {
  CET: 'Central European Time',
  EET: 'Eastern European Time',
  PST: 'Pacific Standard Time',
  EDT: 'Eastern Daylight Time',
  GMT: 'Greenwich Mean Time',
  UTC: 'Coordinated Universal Time',
  EST: 'Eastern Standard Time',
  MST: 'Mountain Standard Time',
  CST: 'Central Standard Time',
  IST: 'Indian Standard Time',
  AEST: 'Australian Eastern Standard Time',
  ACST: 'Australian Central Standard Time',
  AWST: 'Australian Western Standard Time',
  BRT: 'Brazilian Time',
  ART: 'Argentina Time',
  HST: 'Hawaii-Aleutian Standard Time',
  KST: 'Korea Standard Time',
  JST: 'Japan Standard Time',
  WAT: 'West Africa Time',
  CAT: 'Central Africa Time',
  NZST: 'New Zealand Standard Time',
  SST: 'Singapore Standard Time',
  WST: 'Western Standard Time',
  MSK: 'Moscow Standard Time',
  NST: 'Newfoundland Standard Time',
  AKST: 'Alaska Standard Time',
  PHT: 'Philippine Time',
  CEST: 'Central European Summer Time',
  ACDT: 'Australian Central Daylight Time',
  AEDT: 'Australian Eastern Daylight Time',
  IDT: 'Israel Daylight Time',
  PDT: 'Pacific Daylight Time',
  MDT: 'Mountain Daylight Time',
  BST: 'British Summer Time',
  HKT: 'Hong Kong Time',
  CKT: 'Cook Islands Time',
  FJT: 'Fiji Time',
  WIB: 'Western Indonesian Time',
};
