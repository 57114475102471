import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';

import './radio-input.scss';

const b = bem('radio-input');

const RadioInput = (props) => {
  const {
    label = '',
    name = '',
    checked = false,
    disabled = false,
    value,
    onChange,
    className,
    color = 'primary',
    asButton = false,
    ...radioInputProps
  } = props;

  return (
    <label
      className={cn(
        b({
          disabled: disabled,
          checked: checked,
          'is-button': asButton,
          [color]: color,
        }),
        className,
      )}
    >
      <input
        type="radio"
        name={name}
        disabled={disabled}
        className={b('input')}
        value={value}
        checked={onChange && checked}
        defaultChecked={!onChange && checked}
        onChange={onChange}
        {...radioInputProps}
      />
      {!asButton && (
        <div
          className={b('visual', {
            [color]: color,
            checked: checked,
            disabled: disabled,
          })}
        />
      )}
      {label && <span className={b('label')}>{label}</span>}
    </label>
  );
};

// can be extended with color names from ui-kit. Synchronize with $colors in styles file
RadioInput.colorVariants = ['primary', 'leads', 'users', 'interfaces', 'default'];

RadioInput.propTypes = {
  label: TranslationJsx,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  color: PropTypes.oneOf(RadioInput.colorVariants),
  asButton: PropTypes.bool,
};

export default RadioInput;
