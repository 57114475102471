import React from 'react';

import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';

import AppButton, {AppButtonProps} from 'client/common/buttons/app-button';
import Icon from 'client/common/icon';
import {POPOVER_PORTAL_ID} from 'client/common/popovers/popover';

import {TranslationJsx} from 'client/models/language/types';

import cssModule from './dropdown.module.scss';

const b = bem('dropdown', {cssModule});

/**
 * @type {React.FC<InferProps<typeof Dropdown.propTypes>>}
 */
const Dropdown = (props) => {
  const {
    options,
    position = 'bottom',
    commonOnClick = null,
    className = '',
    optionsClassName = null,
    trigger = null,
    triggerProps = {},
    showToggleArrow = false,
    showMenuArrow = false,
    disabled: mainDisabled,
    onOpen,
    offsetParams,
    hideIfEmpty,
    classNames,
    onTriggerClick,
    iconName = 'options',
    isHoverDropdown = false, // if we have dropdown by hovering
  } = props;
  const [isMenuVisible, toggleIsMenuVisible] = useToggle(false);

  const {refs, floatingStyles, context} = useFloating({
    open: isMenuVisible,
    onOpenChange: () => {
      onOpen?.(!isMenuVisible);
      toggleIsMenuVisible();
    },
    middleware: [flip({mainAxis: true}), offset(offsetParams)],
    placement: position,
    whileElementsMounted: autoUpdate,
  });
  const role = useRole(context, {role: 'menu'});
  const dismiss = useDismiss(context);

  const {getFloatingProps} = useInteractions([dismiss, role]);

  const handleToggleMenu = () => {
    onOpen?.(!isMenuVisible);
    toggleIsMenuVisible();
  };

  const renderOption = (option) => {
    const {key, label, value, onClick, disabled, exclude, active, ...restOptionProps} = option;
    if (exclude) {
      return null;
    }
    return (
      <li key={key || value || label} className={b('option-wrapper')} {...restOptionProps}>
        <button
          type="button"
          className={b('option', {disabled, active})}
          disabled={disabled}
          onClick={() => {
            if (onClick) {
              onClick();
            } else if (commonOnClick) {
              commonOnClick(value);
            }
            toggleIsMenuVisible();
          }}
        >
          {option.label}
        </button>
      </li>
    );
  };

  const toggleArrow = showToggleArrow ? (
    <Icon className={b('arrow')} name={isMenuVisible ? 'arrow-up' : 'arrow-down'} />
  ) : null;

  const displayedOptions = options.filter((option) =>
    !option.options ? !option.exclude : !!option.options?.filter((opt) => !opt.exclude).length,
  );

  if (hideIfEmpty && !displayedOptions.length) {
    return null;
  }

  return (
    <div
      className={cn(
        b({'is-menu-visible': isHoverDropdown && isMenuVisible, 'is-menu-hidden': isHoverDropdown && !isMenuVisible}),
        className,
      )}
    >
      <div ref={refs.setReference} className={cn(b('trigger-container'), classNames?.triggerContainer)}>
        {trigger ? (
          <button
            className={cn(b('trigger-wrapper'), classNames?.trigger)}
            onClick={onTriggerClick || handleToggleMenu}
            disabled={mainDisabled}
          >
            {trigger}
            {toggleArrow}
          </button>
        ) : (
          <AppButton
            iconName={iconName}
            color="light-clients"
            onClick={onTriggerClick || handleToggleMenu}
            label={toggleArrow}
            {...triggerProps}
          />
        )}
      </div>
      {isMenuVisible && (
        <FloatingPortal id={POPOVER_PORTAL_ID}>
          <ul
            className={cn(b('options', {[position]: true, arrow: showMenuArrow}), optionsClassName)}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {displayedOptions.map((option) =>
              option.options?.length
                ? [
                    <li key={option.key || options.value || option.label} className={b('group-label')}>
                      {option.label}
                    </li>,
                    ...(option.options?.map(renderOption) || []),
                  ]
                : renderOption(option),
            )}
          </ul>
        </FloatingPortal>
      )}
    </div>
  );
};

const optionPropType = {
  label: PropTypes.oneOfType([TranslationJsx, PropTypes.element]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  exclude: PropTypes.bool,
  active: PropTypes.bool,
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      ...optionPropType,
      options: PropTypes.arrayOf(PropTypes.shape(optionPropType)),
    }),
  ).isRequired,
  hideIfEmpty: PropTypes.bool,
  commonOnClick: PropTypes.func,
  onOpen: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right', 'bottom', 'top', 'top-end', 'top-start', 'bottom-end', 'bottom-start']),
  className: PropTypes.string,
  optionsClassName: PropTypes.string,
  trigger: PropTypes.node,
  triggerProps: PropTypes.shape(AppButtonProps),
  showToggleArrow: PropTypes.bool,
  showMenuArrow: PropTypes.bool,
  offsetParams: PropTypes.object,
  classNames: PropTypes.object,
  iconName: PropTypes.string,
  onTriggerClick: PropTypes.func,

  /* @deprecated prop*/
  grouped: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Dropdown;
