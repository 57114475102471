// TODO: to add modals
export const CATALOG_TYPES = {
  GAME: 'CatalogGame',
  FORM: 'CatalogForm',
  QUIZ: 'CatalogQuiz',
  SCENARIO: 'CatalogScenario',
  TEMPLATE: 'CatalogTemplate',
} as const;

export const CATALOG_TEMPLATE_TABS = {
  WEEZIO: 'weezio',
  SHARED: 'shared',
  MY_TEMPLATES: 'my-templates',
} as const;

export const CATALOG_TYPE_MAP = {
  [CATALOG_TYPES.GAME]: 'game',
  [CATALOG_TYPES.QUIZ]: 'quiz',
  [CATALOG_TYPES.FORM]: 'form',
  [CATALOG_TYPES.TEMPLATE]: 'general',
} as const;

export const TEMPLATE_CATEGORY = {
  NONE: 'none',
  INSTANT_WIN: 'instant_win',
  LOTTERY: 'lottery',
  GENERIC: 'generic',
} as const;

export const TEMPLATE_CATEGORY_RESULT = {
  NONE: 'none',
  WIN: 'win',
  LOSS: 'loss',
} as const;

export const CATALOG_PAGE_TYPES = {
  PAGE: 'page',
  MODULE: 'module',
  GENERAL: 'general',
  TEASER: 'teaser',
  POST: 'post',
  HEADER: 'header',
  FOOTER: 'footer',
  OVERLAY: 'overlay',
} as const;

export const TEMPLATE_TIMEOUT_MODES = {
  PAGE_ENTRY: 'page_entry',
  LAST_ACTION: 'last_action',
};
