import React, {useCallback, useImperativeHandle, useRef, useState} from 'react';

import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cn from 'classnames';

import bem from 'client/services/bem';

import {DEFAULT_ARROW_CONFIG} from './constants';
import {PopoverProps, PopoverRef} from './types';

import cssModule from './popover.module.scss';

export const POPOVER_PORTAL_ID = 'portal-root';

const b = bem('popover', {cssModule});

const Popover = React.forwardRef<PopoverRef, PopoverProps>((props, ref) => {
  const {
    trigger = 'hover',
    role: roleSetting = 'tooltip',
    className,
    triggerClassName,
    overlayInnerStyle,
    contentClassName,
    position = 'bottom',
    flipOptions,
    shiftOptions,
    overlay,
    children,
    offset: offsetValue = 8,
    arrowConfig,
    show,
    hideOnMouseLeave,
    disableFocus,
    setIsPopoverShown,
    disabled,
    toggleByClick = true,
  } = props;
  const {offset: arrowOffset, ...config} = arrowConfig || {};
  const exterArrowConfig = {...DEFAULT_ARROW_CONFIG, ...config};
  const [isOpen, setIsOpenDirect] = useState(show || false);
  const arrowRef = useRef(null);

  const setIsOpen = useCallback(
    (value: boolean) => {
      if (!disabled) {
        setIsOpenDirect(value);
      }
      setIsPopoverShown?.(value);
    },
    [disabled, setIsPopoverShown],
  );

  const {refs, floatingStyles, context} = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: position,
    middleware: [flip(flipOptions), offset(offsetValue), shift(shiftOptions), arrow({element: arrowRef})],
    whileElementsMounted: autoUpdate,
  });

  const focus = useFocus(context);
  const hover = useHover(context, {
    enabled: ['both', 'hover'].includes(trigger),
    handleClose: hideOnMouseLeave ? null : safePolygon(),
  });
  const click = useClick(context, {
    enabled: ['both', 'click'].includes(trigger),
    toggle: toggleByClick,
  });

  const dismiss = useDismiss(context);
  const role = useRole(context, {role: roleSetting});
  const headingId = useId();

  const {getReferenceProps, getFloatingProps} = useInteractions([click, hover, dismiss, focus, role]);

  useImperativeHandle(
    ref,
    () => ({
      setOpen: setIsOpen,
    }),
    [setIsOpen],
  );

  return (
    <>
      <div ref={refs.setReference} className={cn(className, triggerClassName, b())} {...getReferenceProps()}>
        {children}
      </div>
      {isOpen && overlay && (
        <FloatingPortal preserveTabOrder={false} id={POPOVER_PORTAL_ID}>
          <FloatingFocusManager
            context={context}
            modal={true}
            closeOnFocusOut={false}
            returnFocus={true}
            initialFocus={-1}
            visuallyHiddenDismiss
            disabled={disableFocus}
          >
            <div
              className={b('overlay-container')}
              ref={refs.setFloating}
              style={floatingStyles}
              aria-labelledby={headingId}
              {...getFloatingProps()}
            >
              <FloatingArrow
                ref={arrowRef}
                context={context}
                staticOffset={arrowOffset ? `${arrowOffset}%` : null}
                {...exterArrowConfig}
              />
              <div
                className={cn(contentClassName, b('overlay'))}
                style={{...overlayInnerStyle, backgroundColor: exterArrowConfig?.fill}}
              >
                {typeof overlay === 'function' ? overlay({isOpen, setIsOpen}) : overlay}
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
});
export default Popover;
