import isUndefined from 'lodash/isUndefined';

import types from './types';

let init = {
  payload: {
    name: '',
    rootPage: true,
    callback: null,
  },
  // Blocks payload changing to avoid header flickering in UI
  blocked: false,
};

export default function (_state_ = init, action) {
  let state = Object.assign({}, _state_);

  switch (action.type) {
    case types.SET_HEADER_LINK_BACK:
      if (state.blocked) {
        return state;
      }
      return {
        ...state,
        payload: {
          name: action.payload.name,
          callback: action.payload.callback,
          rootPage: !isUndefined(action.payload.rootPage) && action.payload.rootPage,
        },
      };

    case types.CLEAR_HEADER_LINK_BACK:
      if (state.blocked) {
        return state;
      }
      state.payload = action.payload;
      return state;

    case types.SET_HEADER_LINK_BLOCKED:
      state.blocked = action.payload.blocked;
      return state;

    default:
      return state;
  }
}
